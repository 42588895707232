/** Generate error groups for a list of errors.
 *
 * Intended to be used with GroupedErrorTable.
 *
 * Converts a list of operator-action-specific errors into
 * displayable messages and elements for GroupedErrorTable.
 */
import { forEach } from 'lodash'
import { groupBy, map } from 'lodash/fp'
import { WarningGroup } from '~/pages/Workcell/OperatorActions/GroupedWarningTable'
import { ErrorGroup } from './GroupedErrorTable'

export const generateErrorGroups = <TError, TErrorType extends string>(
  errors: TError[],
  // Return the error type. This is used to group errors.
  _getErrorTypeForError: (error: TError) => TErrorType,
  // Return the group header for a given error type.
  getGroupHeaderForErrorType: (errorType: TErrorType, numErrors: number) => string,
  // Return the display element for a given error.
  getErrorDisplayElement: (error: TError) => React.ReactNode,
): ErrorGroup[] => {
  const errorsByType: Record<TErrorType, TError[]> = groupBy(
    _getErrorTypeForError,
    errors,
  ) as Record<TErrorType, TError[]>

  const groups: ErrorGroup[] = []

  forEach(errorsByType, (_errors: TError[], errorType: string) => {
    groups.push({
      errorType: errorType,
      errorGroupHeader: getGroupHeaderForErrorType(
        errorType as TErrorType,
        _errors.length,
      ),
      errors: map(
        (_error: TError) => ({
          displayElement: getErrorDisplayElement(_error),
        }),
        _errors,
      ),
    })
  })

  return groups
}

// TODO: Make this generic if we continue to use this and it doesn't diverge from
// generateErrorGroups
export const generateWarningGroups = <TWarning, TWarningType extends string>(
  warnings: TWarning[],
  // Return the warning type. This is used to group warnings.
  _getWarningTypeForWarning: (warning: TWarning) => TWarningType,
  // Return the group header for a given warning type.
  getGroupHeaderForWarningType: (
    warningType: TWarningType,
    numWarnings: number,
  ) => string,
  // Return the display element for a given warning.
  getWarningDisplayElement: (warning: TWarning) => React.ReactNode,
): WarningGroup[] => {
  const warningsByType: Record<TWarningType, TWarning[]> = groupBy(
    _getWarningTypeForWarning,
    warnings,
  ) as Record<TWarningType, TWarning[]>

  const groups: WarningGroup[] = []

  forEach(warningsByType, (_warnings: TWarning[], warningType: string) => {
    groups.push({
      warningType: warningType,
      warningGroupHeader: getGroupHeaderForWarningType(
        warningType as TWarningType,
        _warnings.length,
      ),
      warnings: map(
        (_warning: TWarning) => ({
          displayElement: getWarningDisplayElement(_warning),
        }),
        _warnings,
      ),
    })
  })

  return groups
}
