import PropTypes from 'prop-types'

import { IconProps } from './types/IconProps'

const ViewHiddenIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='18'
    height='16'
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.60983 12.3927L0.833773 15.1687L1.54088 15.8758L16.5836 0.833083L15.8765 0.125977L13.1077 2.89477C11.8612 2.32083 10.4691 1.99996 9 1.99996C4.90909 1.99996 1.41544 4.48795 0 7.99996C0.724721 9.79814 1.99426 11.3279 3.60983 12.3927ZM4.77027 11.2322L5.966 10.0365C5.55757 9.45682 5.31818 8.75504 5.31818 7.99996C5.31818 6.01597 6.97093 4.39996 9 4.39996C9.74269 4.39996 10.435 4.61646 11.0142 4.98826L11.8851 4.11737C10.9838 3.78062 10.0097 3.59996 9 3.59996C5.89916 3.59996 3.1337 5.30396 1.78368 7.99996C2.46499 9.36053 3.50679 10.4685 4.77027 11.2322ZM9.83058 6.17191C9.57673 6.06139 9.29566 5.99996 9 5.99996C7.87091 5.99996 6.95455 6.89596 6.95455 7.99996C6.95455 8.30499 7.0245 8.59415 7.14958 8.85292L9.83058 6.17191ZM9 14C7.87021 14 6.78598 13.8102 5.77849 13.4615L7.06694 12.1731C7.6889 12.322 8.33678 12.4 9 12.4C12.1009 12.4 14.8664 10.696 16.2164 7.99996C15.6728 6.91441 14.8998 5.9897 13.9677 5.2723L15.1097 4.13032C16.383 5.14849 17.3866 6.47808 18 7.99996C16.5845 11.512 13.0909 14 9 14ZM9 11.6C8.5903 11.6 8.19594 11.5341 7.82743 11.4126L9.25553 9.98448C10.1956 9.86976 10.9382 9.1305 11.0348 8.20521L12.4629 6.77713C12.6045 7.15912 12.6818 7.57094 12.6818 7.99996C12.6818 9.98396 11.0291 11.6 9 11.6Z'
    />
  </svg>
)

ViewHiddenIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ViewHiddenIcon
