/** A table for displaying many warnings grouped by type.
 * Accordions are used for each warning group.
 *
 * Based off GroupedErrorTable. Revisit merging them if they do not continue to diverge.
 */

import cx from 'classnames'
import Accordion from '~/components/Accordion'
import cs from './grouped_warning_table.scss'

export interface Warning {
  displayElement: React.ReactNode
}

export interface WarningGroup {
  warningType: string
  warningGroupHeader: string
  warnings: Warning[]
}

export interface WarningTableProps {
  className?: string
  warningGroups: WarningGroup[]
}

const GroupedWarningTable = ({ className, warningGroups }: WarningTableProps) => {
  return (
    <div className={cx(className, cs.groupedWarningTable)}>
      {warningGroups.map(warningGroup => (
        <Accordion
          key={warningGroup.warningType}
          toggleable
          startOpen
          headerClassName={cs.warningGroupHeader}
          header={warningGroup.warningGroupHeader}
          type='warning'
        >
          <div className={cs.warnings}>
            {warningGroup.warnings.map((warning, index) => (
              <div key={index} className={cs.warning}>
                {warning.displayElement}
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  )
}

export default GroupedWarningTable
