import cx from 'classnames'

import Link from '~/components/Link'
import { useFeatureFlag } from '~/core/featureFlags'

import { useDemoToggleState } from './DemoContext'
import cs from './demo_indicator.scss'

export default function DemoIndicator() {
  const featureEnabled = useFeatureFlag('demoControls')
  const { isActive, isStatic } = useDemoToggleState()

  if (!featureEnabled) {
    return null
  }

  if (isActive && isStatic) {
    return <div className={cx(cs.container, cs.active, cs.static)}>Demo</div>
  }

  return (
    <Link className={cx(cs.container, { [cs.active]: isActive })} to='/demo-controls'>
      {isActive ? 'Demo' : 'Demo Off'}
    </Link>
  )
}
