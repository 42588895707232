// This is heavily lifted off the `...ParseDataErrorGroups` pattern. Revisit this as necessary.

import { RecordManualPassageParsedWorklistWarnings } from '~/api/operatorActions/recordManualPassage'

import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { WarningGroup } from '~/pages/Workcell/OperatorActions/GroupedWarningTable'
import { displayCount } from '~/utils/string'
import { generateWarningGroups } from '../../generateErrorGroups'

export type RecordManualPassageParseDataWarning =
  components['schemas']['ParseCultureDataParentPlateAlreadyExistsWarning']

const _getErrorTypeForWarning = (
  error: RecordManualPassageParseDataWarning,
): RecordManualPassageParseDataWarning['type'] => {
  return error.type
}

const _getGroupHeaderForWarningType = (
  errorType: RecordManualPassageParseDataWarning['type'],
  numErrors: number,
): string => {
  if (errorType === 'parent_plate_already_exists') {
    return `${displayCount('plate', numErrors)} ${pluralize('has', numErrors)} wells with passages already recorded.`
  } else {
    // We should never get here
    return ''
  }
}
const _getErrorDisplayElement = (
  error: RecordManualPassageParseDataWarning,
): React.ReactNode => {
  if (error.type === 'parent_plate_already_exists') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> has wells with passages already recorded from
        parent plate <b>{error.existing_parent_plate_barcode}</b>.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  }
}

export const getRecordManualPassageParseDataWarningGroups = (
  warnings: RecordManualPassageParsedWorklistWarnings,
): WarningGroup[] => {
  return generateWarningGroups<
    RecordManualPassageParseDataWarning,
    RecordManualPassageParseDataWarning['type']
  >(
    warnings,
    _getErrorTypeForWarning,
    _getGroupHeaderForWarningType,
    _getErrorDisplayElement,
  )
}
