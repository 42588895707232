import cx from 'classnames'
import { compact } from 'lodash/fp'

import { CulturePlateStatusFilterGraphQl } from '~/__generated__/graphql'
import PopupMenu from '~/components/PopupMenu'
import { useFeatureFlag } from '~/core/featureFlags'

import cs from './live_cultures_header.scss'

export default function LiveCulturesHeader({
  selectedCultureStatus,
}: { selectedCultureStatus: CulturePlateStatusFilterGraphQl }) {
  const limsCultureData2024 = useFeatureFlag('limsCultureData2024')
  const options = compact([
    limsCultureData2024 && {
      label: 'Bulk Export Culture Metadata...',
      to: '/monitor/bulk-export-culture-metadata',
    },
  ])
  return (
    <div className={cx(cs.header)}>
      <div className={cs.titleContainer}>
        <span className={cs.title}>{getTitle(selectedCultureStatus)}</span>
        {options.length > 0 && <PopupMenu className={cs.menu} options={options} />}
      </div>
      <div className={cs.fill} />
    </div>
  )
}

function getTitle(selectedCultureStatus: CulturePlateStatusFilterGraphQl): string {
  switch (selectedCultureStatus) {
    case CulturePlateStatusFilterGraphQl.Active:
      return 'Active Plates'
    case CulturePlateStatusFilterGraphQl.Inactive:
      return 'Inactive Plates'
    case CulturePlateStatusFilterGraphQl.All:
      return 'All Plates'
    default:
      console.warn(`Unsupported status: ${selectedCultureStatus}`)
      return 'Plates'
  }
}
