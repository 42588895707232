import {
  LiveCulturesQuery,
  LiveCulturesQueryVariables,
  PlatePageQuery,
  PlatePageQueryVariables,
  WellCultureStatusGraphQl,
  WellPageQuery,
  WellPageQueryVariables,
} from '~/__generated__/graphql'

import { ClePlate } from '~/pages/Cle2025/clePlates/ClePlate.interface'
import { getPlateFormatFromDims } from '~/utils/microplate'
import { DemoDerivedRecordsByKind } from './demoData'

/**
 * Prepares query functions for all demoable pages.
 */
export function getDemoQueries(derived: DemoDerivedRecordsByKind) {
  return {
    LiveCultures(_: LiveCulturesQueryVariables): LiveCulturesQuery {
      return { filteredCulturePlates: derived.plates }
    },

    PlatePage({ id }: PlatePageQueryVariables): PlatePageQuery {
      return { culturePlate: derived.plates.find(p => p.id === id) }
    },

    WellPage({ plateId, position }: WellPageQueryVariables): WellPageQuery {
      return {
        culture: derived.wells.find(
          w => w.culturePlate.id === plateId && w.well === position,
        ),
      }
    },
    ClePipeline(): ClePlate[] {
      return derived.plates.map(plate => {
        const format = getPlateFormatFromDims({
          numRows: plate.plateDimensions.rows,
          numCols: plate.plateDimensions.columns,
        })
        return {
          id: plate.barcode,
          plateFormat: format,
          hasAlert: format.includes('24') && plate.plateObservationHistory.length > 2,
          cellLine: {
            cellLineIndex: 1,
            parentCellLine: plate.barcode.split('_')[0],
            desiredEdit: plate.barcode.split('_')[1],
            name: plate.barcode.substring(0, plate.barcode.lastIndexOf('_')),
          },
          phase: plate.barcode.endsWith('_1')
            ? 'seeding'
            : plate.barcode.includes('_2')
              ? '24wp_expansion'
              : '6wp_expansion',
          plateNumber: 1,
          wells: Object.fromEntries(
            plate.wellCultures.map(well => {
              return [
                well.well,
                {
                  confluency: well.observationHistory.at(-1)?.confluence ?? 0,
                  image:
                    well.observationHistory.at(-1)?.montage?.images?.[0].imageUrl ??
                    null,
                },
              ]
            }),
          ),
          owner: 'Dr. Jimmy Sastra',
          status: plate.isCheckedIn
            ? WellCultureStatusGraphQl.Active
            : WellCultureStatusGraphQl.Terminated,
        }
      })
    },
  }
}

export type DemoQueries = ReturnType<typeof getDemoQueries>
