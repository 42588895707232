import { includes } from 'lodash/fp'
import WizardStepper, { WizardStepperStep } from '~/components/Wizard/WizardStepper'
import useWizardData from '~/components/Wizard/useWizardData'
import { useEnabledAndUnusedOperatorActionNames } from '../useEnabledAndUnusedOperatorActionNames'
import useOperatorActionConfig from '../utils/useOperatorActionConfig'
import BulkLoadCulturePlatesHeader from './BulkLoadCulturePlatesHeader'
import BulkLoadCulturePlatesUnknownError from './BulkLoadCulturePlatesUnknownError'
import { BulkLoadCulturePlatesWizardStep } from './BulkLoadCulturePlatesWizardData.interface'
import { BulkLoadCulturePlatesWizardData } from './BulkLoadCulturePlatesWizardData.interface'
import ConfirmUpdatesStep from './ConfirmUpdatesStep'
import SubmitStep from './SubmitStep'
import UploadLiveWellsStep from './UploadLiveWellsStep'
import { getValidStepDataIfComplete as getValidUploadLiveWellsStepDataIfComplete } from './UploadLiveWellsStep'
import UploadPlateDataStep from './UploadPlateDataStep'
import { getValidStepDataIfComplete as getValidUploadPlateDataStepDataIfComplete } from './UploadPlateDataStep'
import cs from './bulk_load_culture_plates_action.scss'
import { isCurrentWizardStepComplete } from './isCurrentWizardStepComplete'

const ORDERED_WIZARD_STEPS: WizardStepperStep<BulkLoadCulturePlatesWizardStep>[] = [
  {
    step: 'uploadPlateData',
    title: 'Upload Plate Data',
  },
  {
    step: 'uploadLiveWells',
    title: 'Upload Live Wells',
  },
  {
    step: 'confirmUpdates',
    title: 'Confirm Updates',
  },
]

export interface BulkLoadCulturePlatesConfig {
  defaultInstrument: string
  defaultShelf: number
}

export const BULK_LOAD_CULTURE_PLATES = 'bulk_load_culture_plates'

const BulkLoadCulturePlatesAction = () => {
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()
  const { config, handleConfigUpdate } =
    useOperatorActionConfig<BulkLoadCulturePlatesConfig>(BULK_LOAD_CULTURE_PLATES)
  const { wizardData, updateCurrentStep, updateFinalSubmitData, updateStepData } =
    useWizardData<BulkLoadCulturePlatesWizardStep, BulkLoadCulturePlatesWizardData>({
      currentStep: 'uploadPlateData',
      stepData: {
        uploadPlateData: {
          data: '',
          unknownFetchError: false,
          selectedInstrument: null,
          selectedShelf: null,
          selectedPlateContents: 'live-culture',
          parseDataResponse: null,
        },
        uploadLiveWells: {
          data: '',
          parseDataResponse: null,
        },
        confirmUpdates: {
          lookupInProgress: false,
        },
      },
      finalSubmitData: { submitInProgress: false, reloadResponse: null },
    })

  if (!includes('bulk_load_culture_plates', enabledOperatorActionNames)) {
    return null
  }

  const renderView = () => {
    if (wizardData.currentStep === 'uploadPlateData' && config) {
      return (
        <UploadPlateDataStep
          config={config}
          stepData={wizardData.stepData.uploadPlateData}
          setStepData={stepData => updateStepData('uploadPlateData', stepData)}
        />
      )
    }
    if (wizardData.currentStep === 'uploadLiveWells') {
      const validUploadDataStepData =
        getValidUploadPlateDataStepDataIfComplete(wizardData)
      // This should never happen. See isCurrentWizardStepComplete.
      if (validUploadDataStepData === null) {
        return <BulkLoadCulturePlatesUnknownError />
      }

      return (
        <UploadLiveWellsStep
          uploadPlateStepData={validUploadDataStepData}
          stepData={wizardData.stepData.uploadLiveWells}
          setStepData={stepData => updateStepData('uploadLiveWells', stepData)}
        />
      )
    }
    if (wizardData.currentStep === 'confirmUpdates') {
      const validUploadPlateDataStepData =
        getValidUploadPlateDataStepDataIfComplete(wizardData)
      const validUploadLiveWellsStepData =
        getValidUploadLiveWellsStepDataIfComplete(wizardData)
      // This should never happen. See isCurrentWizardStepComplete.
      if (
        validUploadPlateDataStepData === null ||
        validUploadLiveWellsStepData === null
      ) {
        return <BulkLoadCulturePlatesUnknownError />
      }

      return (
        <ConfirmUpdatesStep
          uploadPlateDataStepData={validUploadPlateDataStepData}
          uploadLiveWellsStepData={validUploadLiveWellsStepData}
        />
      )
    }

    if (wizardData.currentStep === '_finalSubmitStep') {
      const validUploadPlateDataStepData =
        getValidUploadPlateDataStepDataIfComplete(wizardData)
      const validUploadLiveWellsStepData =
        getValidUploadLiveWellsStepDataIfComplete(wizardData)
      // This should never happen. See isCurrentWizardStepComplete.
      if (
        validUploadPlateDataStepData === null ||
        validUploadLiveWellsStepData === null
      ) {
        return <BulkLoadCulturePlatesUnknownError />
      }

      return (
        <SubmitStep
          stepData={wizardData.finalSubmitData}
          setStepData={updateFinalSubmitData}
          uploadPlateDataStepData={validUploadPlateDataStepData}
          uploadLiveWellsStepData={validUploadLiveWellsStepData}
        />
      )
    }
    return null
  }

  return (
    <div className={cs.bulkLoadCulturePlatesAction}>
      <BulkLoadCulturePlatesHeader
        className={cs.header}
        handleConfigUpdate={handleConfigUpdate}
      />
      <WizardStepper<BulkLoadCulturePlatesWizardStep>
        className={cs.stepper}
        steps={ORDERED_WIZARD_STEPS}
        currentStep={wizardData.currentStep}
        submitLabel='Save'
        isCurrentStepComplete={isCurrentWizardStepComplete(wizardData)}
        onCurrentStepUpdate={updateCurrentStep}
      />
      {renderView()}
    </div>
  )
}

export default BulkLoadCulturePlatesAction
