import { find, flatten, includes, map, reject } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { getWellMatrix } from '~/utils/microplate'
import {
  UploadLiveWellsStepData,
  UploadPlateDataStepValidData,
} from './BulkLoadCulturePlatesWizardData.interface'
import { BulkLoadPreviewPlate } from './BulkLoadPlatePreview'

export const getPlateLiveWells = (
  plateBarcode: string,
  plateFormat: supportedPlateFormats,
  uploadPlateStepData: UploadPlateDataStepValidData,
  uploadLiveWellsData: UploadLiveWellsStepData,
): string[] => {
  const validatedPlate = find(
    ['user_facing_id', plateBarcode],
    uploadPlateStepData.parseDataResponse.culture_plates,
  )

  // Note: Unclear if this is required. This logic should already have been handled by the backend.
  const defaultLiveWells =
    uploadPlateStepData.selectedPlateContents == 'pre-coated'
      ? []
      : flatten(getWellMatrix(plateFormat))
  let liveWells = validatedPlate ? validatedPlate.live_wells : defaultLiveWells

  if (
    uploadLiveWellsData.parseDataResponse !== null &&
    uploadLiveWellsData.parseDataResponse.type === 'plates'
  ) {
    const plateWithLiveWells = find(
      ['plate_barcode', plateBarcode],
      uploadLiveWellsData.parseDataResponse.data,
    )

    liveWells = plateWithLiveWells ? plateWithLiveWells.live_wells : liveWells
  }
  return liveWells
}

export const getBulkLoadPreviewPlates = (
  uploadPlateDataStepData: UploadPlateDataStepValidData,
  uploadLiveWellsStepData: UploadLiveWellsStepData,
): BulkLoadPreviewPlate[] => {
  const newCulturePlateBarcodes = map(
    'user_facing_id',
    reject(
      ['already_created', true],
      uploadPlateDataStepData.parseDataResponse.culture_plates,
    ),
  )
  return uploadPlateDataStepData.parseDataResponse.data.map(
    plateWithStorageLoadLocation => {
      return {
        plateBarcode: plateWithStorageLoadLocation.plate_barcode,
        plateFormat: plateWithStorageLoadLocation.plate_format,
        liveWells: getPlateLiveWells(
          plateWithStorageLoadLocation.plate_barcode,
          plateWithStorageLoadLocation.plate_format,
          uploadPlateDataStepData,
          uploadLiveWellsStepData,
        ),
        isNew: includes(
          plateWithStorageLoadLocation.plate_barcode,
          newCulturePlateBarcodes,
        ),
      }
    },
  )
}

export const getNumPlates = (plates: BulkLoadPreviewPlate[]): number => {
  return plates.length
}

export const getTotalLiveWells = (plates: BulkLoadPreviewPlate[]): number => {
  return plates.reduce((sum, plate) => sum + plate.liveWells.length, 0)
}
