import {
  CulturePlateGraphQl,
  PlateImagingObservationGraphQl,
  SubImageSizeGraphQl,
} from '~/__generated__/graphql'

import dayjs from 'dayjs'
import { DemoObjectConverter } from '../demoData'
import { getDemoPlateSprite } from '../imageSets'
import { DemoSourceTimepoint, plateObservationKey } from './demoTimepoint'
import { DemoSourceWell, demoWellKey } from './demoWell'

export type DemoSourcePlate = {
  barcode: string
  format: string
  seeded: string
  checkedOut: string
}

type PartialPlate = Omit<
  CulturePlateGraphQl,
  'plateObservationHistory' | 'wellCultures'
> & {
  relevantTimepoints: DemoSourceTimepoint[]
  wellCultures: DemoSourceWell[]
}

export const plateConverter: DemoObjectConverter<
  DemoSourcePlate,
  PartialPlate,
  CulturePlateGraphQl
> = {
  tsvColumns: ['barcode', 'format', 'seeded', 'checkedOut'],

  validate(plates) {
    if (plates.length !== new Set(plates.map(p => p.barcode)).size) {
      throw new Error('There are duplicate plate barcodes.')
    }
    if (plates.some(p => !['96', '24', '12', '6'].includes(p.format))) {
      throw new Error('Unsupported plate format')
    }
    if (plates.some(p => p.seeded != '' && Number.isNaN(Number(p.seeded)))) {
      throw new Error('Plate seeded must be a number of days before/after present')
    }
    if (plates.some(p => p.checkedOut != '' && Number.isNaN(Number(p.checkedOut)))) {
      throw new Error('Plate checkedOut must be a number of days before/after present')
    }
  },

  convert(sourcePlate, others) {
    const wells =
      others.wells?.filter(w => w.plateBarcode === sourcePlate.barcode) ?? []

    const seeded = sourcePlate.seeded
      ? dayjs().add(Number(sourcePlate.seeded), 'days').toISOString()
      : '2024-11-05T17:05:00.000Z'
    const checkedOutAt = sourcePlate.checkedOut
      ? dayjs().add(Number(sourcePlate.checkedOut), 'days').toISOString()
      : null

    const defaults = {
      id: encodeURIComponent(btoa(sourcePlate.barcode)),
      checkedInAt: seeded,
      firstCheckedInAt: seeded,
      checkedOutAt: checkedOutAt ?? '0001-01-01T00:00:00.000Z',
      isCheckedIn: checkedOutAt == null,
      plateDimensions: plateFormatToDims(sourcePlate.format),
      wellCultures: wells,
    }

    return {
      partialItem: {
        __typename: 'CulturePlateGraphQL',
        ...defaults,
        ...sourcePlate,
        relevantTimepoints:
          others.timepoints?.filter(t => t.plateBarcode === sourcePlate.barcode) ?? [],
      },
      lookupKey: sourcePlate.barcode,
    }
  },

  link(derived, partial, lookup) {
    derived.wellCultures = partial.wellCultures
      .map(w => lookup('wells', demoWellKey(w)))
      .filter(w => w != null)

    const activeWells = new Set(derived.wellCultures.map(w => w.well))
    const plateObservations: { [timestamp: string]: PlateImagingObservationGraphQl } =
      {}
    for (const timepoint of partial.relevantTimepoints) {
      const ts = timepoint.timestamp
      if (!plateObservations[ts]) {
        const { url, wellSequence, montageLayout } = getDemoPlateSprite(
          timepoint.plateImageSet,
          // Ideally we'd give each component the size it requested in its
          // query. But the demo query framework doesn't support field args, so
          // we need one size that will work for all cases. 800x800 sprites
          // aren't available for 96 well plates due to size limits imposed by
          // the JPEG format, so we use 600x600.
          SubImageSizeGraphQl.Size_600X600,
        )
        plateObservations[ts] = {
          id: plateObservationKey(timepoint),
          timestamp: ts,
          confluences: [],
          sprite: {
            id: url,
            datasetId: plateObservationKey(timepoint),
            imageUrl: url,
            montageLayout,
            // Normally we'd show images for all available wells. But, to allow
            // people to customize the demo without re-generating plate sprites
            // every time, we mask the wellSequence entries for wells that are
            // not specified in the demo. This prevents image UIs from
            // displaying inactive wells, while keeping the correct alignment of
            // the active well positions in the sprite.
            wellSequence: wellSequence.map(w =>
              activeWells.has(w) ? w : `DEMO_INACTIVE_${w}`,
            ),
          },
        }
      }
      plateObservations[ts].confluences.push({
        well: timepoint.wellPosition,
        value: parseFloat(timepoint.confluence),
      })
    }
    derived.plateObservationHistory = Object.values(plateObservations)
  },
}

function plateFormatToDims(format?: string): { rows: number; columns: number } {
  switch (format) {
    case '':
    case undefined:
    case '96':
      return { rows: 8, columns: 12 }
    case '24':
      return { rows: 4, columns: 6 }
    case '12':
      return { rows: 3, columns: 4 }
    case '6':
      return { rows: 2, columns: 3 }
    default:
      throw new Error(`Unsupported plate format "${format}"`)
  }
}
