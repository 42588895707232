import cx from 'classnames'
import dayjs from 'dayjs'
import { WellPageQuery } from '~/__generated__/graphql'
import MontageHero from '~/components/MontageHero'
import TrendingFlatIcon from '~/components/icons/TrendingFlatIcon'
import cs from './CultureLineage.scss'

type Neighbor = 'culture' | 'passage' | undefined

const Observation = ({
  culture,
  observationTimestamp,
  before,
  after,
  selected,
  openCulture,
}: {
  culture: WellPageQuery['culture']
  observationTimestamp: string
  before: Neighbor
  after: Neighbor
  selected: boolean
  openCulture: (plateId: string, well: string, timestamp: string) => void
}) => {
  const checkedInAt = dayjs(culture?.culturePlate?.firstCheckedInAt)
  const observationAt = dayjs(observationTimestamp)
  const days = observationAt.diff(checkedInAt, 'days')
  const hours = observationAt.diff(checkedInAt, 'hours') - days * 24
  const confluence = culture?.observationHistory.find(
    obs => obs.timestamp === observationTimestamp,
  )?.confluence

  return (
    <div
      className={cs.filmItem}
      onClick={() => {
        if (culture?.culturePlate.id && culture?.well) {
          openCulture(culture?.culturePlate.id, culture?.well, observationTimestamp)
        }
      }}
    >
      <div className={cs.observationTime}>
        Day {days}, Hour {hours}
      </div>
      <div className={cs.timeline}>
        <svg width='248' height='12'>
          <circle cx='124' cy='6' r='4' stroke='#2cb1bc' strokeWidth='3' fill='none' />
          {before && (
            <line
              x1='0'
              y1='6'
              x2='118'
              y2='6'
              stroke='#2cb1bc'
              strokeWidth='3'
              strokeDasharray={before === 'passage' ? '6,6' : undefined}
            />
          )}
          {after && (
            <line
              x1='130'
              y1='6'
              x2='248'
              y2='6'
              stroke='#2cb1bc'
              strokeWidth='3'
              strokeDasharray={after === 'passage' ? '6,6' : undefined}
            />
          )}
        </svg>
      </div>
      <div className={cs.observationData}>{confluence} %</div>
      <div className={selected ? cx(cs.imagery, cs.selected) : cs.imagery}>
        <MontageHero
          culture={culture as any}
          observationTimestamp={observationTimestamp}
        />
      </div>
    </div>
  )
}

const Passage = () => {
  return (
    <div className={cs.filmItem}>
      <div className={cs.observationTime}></div>
      <div className={cs.timeline}>
        <svg width='48' height='12'>
          <line
            x1='0'
            y1='6'
            x2='48'
            y2='6'
            stroke='#2cb1bc'
            strokeWidth='3'
            strokeDasharray='6,6'
          />
        </svg>
      </div>
      <div className={cs.observationData}></div>
      <div className={cs.arrow}>
        <TrendingFlatIcon className={cs.arrowIcon} />
      </div>
    </div>
  )
}

const CultureLineage = ({
  isOpen,
  onClose,
  cultureData,
  parentCultures,
  openCulture,
  selectedTimestamp,
}: {
  isOpen: boolean
  onClose: () => void
  cultureData?: WellPageQuery
  parentCultures: NonNullable<WellPageQuery['culture']>[]
  openCulture: (plateId: string, well: string, timestamp: string) => void
  selectedTimestamp: string | undefined
}) => {
  const items: JSX.Element[] = []
  const cultureLineageHistory = (parentCultures ?? []).toReversed()

  const selectedTimestampActual =
    selectedTimestamp ??
    parentCultures[0]?.observationHistory[
      parentCultures[0].observationHistory.length - 1
    ]?.timestamp
  for (let i = 0; i < cultureLineageHistory.length; i++) {
    const culture = cultureLineageHistory[i]
    const observations: JSX.Element[] = []

    culture.observationHistory.forEach((obs, obsIndex) => {
      let before: 'culture' | 'passage' | undefined
      if (obsIndex === 0) {
        before = i === 0 ? undefined : 'passage'
      } else {
        before = 'culture'
      }

      let after: 'culture' | 'passage' | undefined
      if (obsIndex === culture.observationHistory.length - 1) {
        after = i === cultureLineageHistory.length - 1 ? undefined : 'passage'
      } else {
        after = 'culture'
      }

      observations.push(
        <Observation
          culture={culture}
          observationTimestamp={obs.timestamp}
          before={before}
          after={after}
          selected={obs.timestamp === selectedTimestampActual}
          openCulture={openCulture}
        />,
      )
    })

    if (i < cultureLineageHistory.length - 1) {
      observations.push(<Passage />)
    }
    observations.reverse()

    items.push(
      <div className={cs.filmGroup}>
        <div className={cs.cultureName}>
          <span className={cs.cultureSpan}>
            {atob(decodeURIComponent(culture?.culturePlate?.id ?? ''))}, Well{' '}
            {culture?.well}
          </span>
        </div>
        <div className={cs.observations}>{observations}</div>
      </div>,
    )
  }

  items.reverse()

  return (
    <div className={cs.container}>
      <div className={cs.header}>
        <div className={cs.headerLeft}>
          <div className={cs.title}>Culture Lineage</div>
        </div>
      </div>
      <div className={cs.filmStrip}>{items}</div>
    </div>
  )
}

export default CultureLineage
