import { WellCultureStatusGraphQl } from '~/__generated__/graphql'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'

export type ClePhase = 'seeding' | '24wp_expansion' | '6wp_expansion'
export const CLE_PHASES: ClePhase[] = ['seeding', '24wp_expansion', '6wp_expansion']

export interface CellLine {
  cellLineIndex: number
  parentCellLine: string
  desiredEdit: string
  name: string
}

export interface Well {
  confluency: number
  image: string | null
}

export interface ClePlate {
  id: string
  plateFormat: supportedPlateFormats
  hasAlert: boolean
  cellLine: CellLine
  phase: ClePhase
  plateNumber: number
  wells: Record<string, Well>
  owner: string
  status: WellCultureStatusGraphQl
}

export const getCellLineName = (cellLine: CellLine): string => {
  return `${cellLine.parentCellLine}-${cellLine.desiredEdit}`
}

export const plateNumberToChar = (plateNumber: number): string => {
  return String.fromCharCode(plateNumber + 64)
}

export const getClePlateType = (phase: ClePhase): string => {
  if (phase === 'seeding') {
    return 'Seeding'
  }
  if (phase === '24wp_expansion') {
    return 'Expansion Stage 1'
  }
  return 'Expansion Stage 2'
}

export const getClePhasePlateFormat = (phase: ClePhase): string => {
  if (phase === 'seeding') {
    return '96 Well'
  }
  if (phase === '24wp_expansion') {
    return '24 Well'
  }
  return '6 Well'
}

export const getClePhaseName = (plate: ClePlate): string => getClePlateType(plate.phase)
