import cx from 'classnames'
import { WellCultureStatusGraphQl } from '~/__generated__/graphql'
import {
  ClePhase,
  ClePlate,
  getClePhasePlateFormat,
  getClePlateType,
} from '../clePlates/ClePlate.interface'
import cs from './cle_pipeline_viz.scss'

const getNumPlatesForPhase = (plates: ClePlate[], phase: ClePhase) => {
  return plates.filter(
    plate =>
      plate.phase === phase &&
      plate.status === WellCultureStatusGraphQl.Active &&
      !plate.id.endsWith('_2D'),
  ).length
}

const getNumPlatesRequiringReviewForPhase = (plates: ClePlate[], phase: ClePhase) => {
  const platesWithAlerts = plates.filter(
    plate =>
      plate.hasAlert &&
      plate.phase === phase &&
      plate.status === WellCultureStatusGraphQl.Active,
  )
  return platesWithAlerts.length
}

const ClePipelinePhase = ({
  phase,
  plates,
  selectedPhase,
  setSelectedPhase,
}: {
  phase: ClePhase
  plates: ClePlate[]
  selectedPhase: ClePhase | null
  setSelectedPhase: (phase: ClePhase | null) => void
}) => {
  const plateCount = getNumPlatesForPhase(plates, phase)
  const alertCount = getNumPlatesRequiringReviewForPhase(plates, phase)

  const handleClick = () => {
    if (selectedPhase === phase) {
      setSelectedPhase(null)
    } else {
      setSelectedPhase(phase)
    }
  }

  return (
    <div
      className={cx(
        cs.clePipelinePhase,
        selectedPhase !== null && selectedPhase !== phase && cs.disabled,
      )}
    >
      <div className={cs.plateCountContainer}>
        <div className={cs.plateCount} onClick={handleClick}>
          {plateCount}
        </div>
        <div className={cs.dottedLine} />
        {alertCount > 0 && (
          <div className={cs.alertCount} onClick={handleClick}>
            {alertCount}
          </div>
        )}
      </div>
      <div className={cs.phase} onClick={handleClick}>
        {getClePlateType(phase)}
      </div>
      <div className={cs.plateFormat} onClick={handleClick}>
        {getClePhasePlateFormat(phase)}
      </div>
    </div>
  )
}

const ClePipelineViz = ({
  plates,
  selectedPhase,
  setSelectedPhase,
}: {
  plates: ClePlate[]
  selectedPhase: ClePhase | null
  setSelectedPhase: (phase: ClePhase | null) => void
}) => {
  return (
    <div className={cs.clePipelineViz}>
      <ClePipelinePhase
        phase='seeding'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='24wp_expansion'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='6wp_expansion'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
    </div>
  )
}

export default ClePipelineViz
