import { MontageLayoutGraphQl, SubImageSizeGraphQl } from '~/__generated__/graphql'
import { PlateDims, convertWellIndexToWellName } from '~/utils/microplate'

/**
 * Instructions for preparing an image set:
 * https://www.notion.so/monomer/Preparing-demo-image-sets-1568d59ea9ff8043a0d0c490f9a009b8
 */
export interface DemoImageSet {
  /** A sales-facing name for the cell types depicted in the image set. */
  name: string

  /** A sales-facing description of the image set. */
  description: string

  /** A canonical identifier for the image set's license. */
  license: string

  /** Attribution text that must be published along with the images. */
  citation: string

  /**
   * A list of relative filepaths of original files.
   *
   * @deprecated This is not currently used in demos.
   */
  originals?: string[]

  /**
   * A list of prefixes where plate sprites can be found, along with their
   * individual site thumbnails.
   */
  plateObservations?: DemoPlateObservation[]
}

export interface DemoPlateObservation {
  spriteDirectory: string
  label: string

  wellSequence: string[]
  montageLayout: MontageLayoutGraphQl

  montageThumbnails: {
    200: { [well: string]: string[] }
    2000: { [well: string]: string[] }
  }
}

/**
 * Returns the URL and other info about a demo plate sprite. The sprite must
 * already exist. See the comment on ImageSet for instructions on how to
 * generate sprites.
 */
export function getDemoPlateSprite(
  spriteDirectory: string,
  subImageSize: SubImageSizeGraphQl,
): { url: string; wellSequence: string[]; montageLayout: MontageLayoutGraphQl } {
  const obs = spriteDirectoryToPlateObservation[spriteDirectory]
  if (obs == null) {
    console.error(`Invalid sprite directory '${spriteDirectory}'`)
    return { url: '', wellSequence: [], montageLayout: MontageLayoutGraphQl.Single }
  }

  const spriteFilename = {
    [SubImageSizeGraphQl.Size_200X200]: 'sprite-v1-200x200.jpg',
    [SubImageSizeGraphQl.Size_400X400]: 'sprite-v1-400x400.jpg',
    [SubImageSizeGraphQl.Size_600X600]: 'sprite-v1-600x600.jpg',
    [SubImageSizeGraphQl.Size_800X800]: 'sprite-v1-800x800.jpg',
  }[subImageSize]
  if (!spriteFilename) {
    throw new Error(`Invalid sub image size "${subImageSize}"`)
  }

  const { wellSequence, montageLayout } = obs
  return {
    url: `https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/plate_observations/${spriteDirectory}/${spriteFilename}`,
    wellSequence,
    montageLayout,
  }
}

/**
 * Returns an array of individual montage site thumbnails for a particular well
 * on a sprite.
 */
export function getDemoMontageThumbnails(
  spriteDirectory: string,
  well: string,
  thumbnailSize: 200 | 2000,
): string[] {
  const obs = spriteDirectoryToPlateObservation[spriteDirectory]
  if (obs == null) {
    console.error(`Invalid sprite directory '${spriteDirectory}'`)
    return []
  }
  return obs.montageThumbnails[thumbnailSize][well].map(
    path =>
      `https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/plate_observations/${path}`,
  )
}

const WELL_SEQUENCE_6WP = wellSequence({ numRows: 2, numCols: 3 })
const WELL_SEQUENCE_24WP = wellSequence({ numRows: 4, numCols: 6 })
const WELL_SEQUENCE_96WP = wellSequence({ numRows: 8, numCols: 12 })

const imageSets: DemoImageSet[] = [
  {
    name: 'Simulated single-colony iPSC',
    description:
      '5 timepoints of simulated growth of a single colony in a 1x1 montage. Images created by Casey. Note: There is only one well. Do not use this in situations where you plan to show multiple wells, because all wells will show the same image.',
    license: 'Unknown',
    citation: 'Unknown',
    plateObservations: [1, 2, 3, 4, 5].map(t => ({
      spriteDirectory: `simulated/single_colony_ipsc/t${t}`,
      label: `timepoint ${t}`,
      wellSequence: WELL_SEQUENCE_96WP,
      montageLayout: MontageLayoutGraphQl.Single,
      montageThumbnails: {
        200: groupIntoWells(
          WELL_SEQUENCE_96WP.map(() => `simulated/single_colony_ipsc/200/t${t}.jpg`),
          WELL_SEQUENCE_96WP,
          1,
        ),
        2000: groupIntoWells(
          WELL_SEQUENCE_96WP.map(() => `simulated/single_colony_ipsc/2000/t${t}.jpg`),
          WELL_SEQUENCE_96WP,
          1,
        ),
      },
    })),
  },
  {
    name: 'iPSC (Science Corp)',
    description: '7 timepoints of a 6 well plate of iPSC cells.',
    license: 'Unknown',
    citation: 'Unknown',
    plateObservations: [
      '220928_110653_600__20220928_105655',
      '220929_122926_600__20220929_121947',
      '220930_103235_600__20220930_102257',
      '221001_111836_600__20221001_110902',
      '221002_102626_600__20221002_101644',
      '221003_124926_600__20221003_123947',
      '221004_145431_600__20221004_144601',
    ].flatMap((obsName, i) => [
      {
        spriteDirectory: `science/2022_11_01_cell_image_batch/${obsName}/16x6`,
        label: `6wp original - day ${i}`,
        wellSequence: WELL_SEQUENCE_6WP,
        montageLayout: MontageLayoutGraphQl.Square_4X4,
        montageThumbnails: {
          200: groupIntoWells(
            allScienceCorpIPSCThumbs(obsName, 200),
            WELL_SEQUENCE_6WP,
            16,
          ),
          2000: groupIntoWells(
            allScienceCorpIPSCThumbs(obsName, 2000),
            WELL_SEQUENCE_6WP,
            16,
          ),
        },
      },
      {
        spriteDirectory: `science/2022_11_01_cell_image_batch/${obsName}/4x24`,
        label: `24wp reformat - day ${i}`,
        wellSequence: WELL_SEQUENCE_24WP,
        montageLayout: MontageLayoutGraphQl.Square_2X2,
        montageThumbnails: {
          200: groupIntoWells(
            allScienceCorpIPSCThumbs(obsName, 200),
            WELL_SEQUENCE_24WP,
            4,
          ),
          2000: groupIntoWells(
            allScienceCorpIPSCThumbs(obsName, 2000),
            WELL_SEQUENCE_24WP,
            4,
          ),
        },
      },
      {
        spriteDirectory: `science/2022_11_01_cell_image_batch/${obsName}/1x96`,
        label: `96wp reformat - day ${i}`,
        wellSequence: WELL_SEQUENCE_96WP,
        montageLayout: MontageLayoutGraphQl.Single,
        montageThumbnails: {
          200: groupIntoWells(
            allScienceCorpIPSCThumbs(obsName, 200),
            WELL_SEQUENCE_96WP,
            1,
          ),
          2000: groupIntoWells(
            allScienceCorpIPSCThumbs(obsName, 2000),
            WELL_SEQUENCE_96WP,
            1,
          ),
        },
      },
    ]),
  },
  {
    name: 'Cardiac Organoids',
    description:
      'Two 96-well plates of cardiac organoids. Plate CD318 has 17 timepoints, while plate CD322 has 16 timepoints.',
    license: 'Proprietary',
    citation:
      'DO NOT ATTRIBUTE. Customer gave permission to use without attribution, but asked to approve collateral where their name is used.',
    plateObservations: [
      '231223_112938_CD318_TnnT__20231223_112937',
      '231221_091518_CD318_TnnT__20231221_091517',
      '231222_074159_CD318_TnnT__20231222_074158',
      '231222_100017_CD322_5uM__20231222_100017',
      '231223_115139_CD322_5uM__20231223_115139',
      '231224_092755_CD318_TnnT__20231224_092755',
      '231224_094955_CD322_5uM__20231224_094955',
      '231225_123354_CD318_TnnT__20231225_123354',
      '231225_125545_CD322_5uM__20231225_125545',
      '231226_122541_CD318_TnnT__20231226_122541',
      '231226_124736_CD322_5uM__20231226_124736',
      '231227_102919_CD318_TnnT__20231227_102919',
      '231227_105111_CD322_5uM__20231227_105111',
      '231228_104806_CD318_TnnT__20231228_104805',
      '231228_111004_CD322_5uM__20231228_111004',
      '231229_095205_CD318_TnnT__20231229_095205',
      '231229_100832_CD322_5uM__20231229_100832',
      '231230_092822_CD318_TnnT__20231230_092821',
      '231230_095017_CD322_5uM__20231230_095016',
      '231231_090051_CD322_5uM__20231231_090050',
      '240101_101511_CD318_TnnT__20240101_101511',
      '240102_090124_CD322_5uM__20240102_090123',
      '240103_105104_CD318_TnnT__20240103_105103',
      '240104_133640_CD322_5uM__20240104_133640',
      '240105_100751_CD318_TnnT__20240105_100751',
      '240106_093916_CD322_5uM__20240106_093915',
      '240107_094215_CD318_TnnT__20240107_094214',
      '240108_121131_CD318_TnnT__20240108_121131',
      '240108_123324_CD322_5uM__20240108_123324',
      '240109_094815_CD318_TnnT__20240109_094814',
      '240109_101009_CD322_5uM__20240109_101009',
      '240110_082104_CD318_TnnT__20240110_082104',
      '240110_084303_CD322_5uM__20240110_084302',
    ].map(t => ({
      spriteDirectory: `anonymouscustomer1/organoid_demo_2024_v2/sprites/${t}`,
      label: `${t.slice(14, 19)} ${t.slice(2, 4)}/${t.slice(4, 6)}`,
      wellSequence: WELL_SEQUENCE_96WP,
      montageLayout: MontageLayoutGraphQl.Single,
      montageThumbnails: {
        200: groupIntoWells(
          WELL_SEQUENCE_96WP.map(
            well =>
              `anonymouscustomer1/organoid_demo_2024_v2/200/${t}/${well}_03_1_1_Bright Field_001.jpg`,
          ),
          WELL_SEQUENCE_96WP,
          1,
        ),
        2000: groupIntoWells(
          WELL_SEQUENCE_96WP.map(
            well =>
              `anonymouscustomer1/organoid_demo_2024_v2/2000/${t}/${well}_03_1_1_Bright Field_001.jpg`,
          ),
          WELL_SEQUENCE_96WP,
          1,
        ),
      },
    })),
  },

  // Legacy format, not used in current demos. When an image sets below becomes
  // needed, we should migrate it to the above `plateObservations` format.
  {
    name: 'HEK293T',
    description:
      "12 DIC images (pixel size 2.5 x 2.5 µm) of confluent HEK293T cells were acquired using LSM-410 microscope (Zeiss, Germany) in non-confocal mode from a multi-well wound healing experiment in Ilan Tsarfay's laboratory at Tel Aviv University.",
    license: 'CC-BY 3.0 Unported',
    citation:
      'Image set BBBC019v2 from the Broad Bioimage Benchmark Collection [Ljosa et al., Nature Methods, 2012].',
    originals: [
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L1_Sum001.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L10_Sum055.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L12_Sum030.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L15_Sum030.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L18_Sum030.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L21_Sum001.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L23_Sum001.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L25_Sum055.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L29_Sum020.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L5_Sum010.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L7_Sum020.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L8_Sum055.tif',
    ],
  },
  {
    name: 'Brain metastatic melanoma',
    description:
      '20 images of cell populations of brain metastatic melanoma during a would healing experiment were acquired in the I. Witz lab using an inverted microscope (Eclipse TE 2000-S; Nikon, Enfield Enfield, CT, USA) fitted with a digital camera (DXM1200F; Nikon).',
    license: 'CC-BY 3.0 Unported',
    citation:
      'Image set BBBC019v2 from the Broad Bioimage Benchmark Collection [Ljosa et al., Nature Methods, 2012].',
    originals: [
      'bbbc/BBBC019v2/Melanoma/EXP1_1_0HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP1_1_24HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP1_2_27HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP1_2_4HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP2_1_28HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP2_1_4HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP2_2_0HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP2_2_20HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_1_0HRS_003.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_1_24HRS_003.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_2_29HRS_003.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_2_4HRS_003.tif',
      'bbbc/BBBC019v2/Melanoma/EXP4_1_26HRS_001.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP4_1_4HRS_001.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP4_2_0HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP4_2_24HRS_001.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_1_0HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_1_24HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_2_26HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_2_4HRS_002.jpg',
    ],
  },
  {
    name: 'CHO',
    description:
      'The image set consists of 60 Differential Interference Contrast (DIC) images of Chinese Hamster Ovary (CHO) cells. The images are taken on an Olympus Cell-R microscope with a 20x lens at the time when the cell initiated their attachment to the bottom of the dish.',
    license: 'CC-BY 3.0 Unported',
    citation:
      'Image set BBBC030v1 [Koos, K., Molnár, J., Kelemen, L., Tamás, G., & Horvath, P. (2016). DIC image reconstruction using an energy minimization framework to visualize optical path length distribution. Scientific reports, 6.] from the Broad Bioimage Benchmark Collection',
    originals: [
      'bbbc/BBBC030/cho01.png',
      'bbbc/BBBC030/cho02.png',
      'bbbc/BBBC030/cho03.png',
      'bbbc/BBBC030/cho04.png',
      'bbbc/BBBC030/cho05.png',
      'bbbc/BBBC030/cho06.png',
      'bbbc/BBBC030/cho07.png',
      'bbbc/BBBC030/cho08.png',
      'bbbc/BBBC030/cho09.png',
      'bbbc/BBBC030/cho10.png',
      'bbbc/BBBC030/cho11.png',
      'bbbc/BBBC030/cho12.png',
      'bbbc/BBBC030/cho13.png',
      'bbbc/BBBC030/cho14.png',
      'bbbc/BBBC030/cho15.png',
      'bbbc/BBBC030/cho16.png',
      'bbbc/BBBC030/cho17.png',
      'bbbc/BBBC030/cho18.png',
      'bbbc/BBBC030/cho19.png',
      'bbbc/BBBC030/cho20.png',
      'bbbc/BBBC030/cho21.png',
      'bbbc/BBBC030/cho22.png',
      'bbbc/BBBC030/cho23.png',
      'bbbc/BBBC030/cho24.png',
      'bbbc/BBBC030/cho25.png',
      'bbbc/BBBC030/cho26.png',
      'bbbc/BBBC030/cho27.png',
      'bbbc/BBBC030/cho28.png',
      'bbbc/BBBC030/cho29.png',
      'bbbc/BBBC030/cho30.png',
      'bbbc/BBBC030/cho31.png',
      'bbbc/BBBC030/cho32.png',
      'bbbc/BBBC030/cho33.png',
      'bbbc/BBBC030/cho34.png',
      'bbbc/BBBC030/cho35.png',
      'bbbc/BBBC030/cho36.png',
      'bbbc/BBBC030/cho37.png',
      'bbbc/BBBC030/cho38.png',
      'bbbc/BBBC030/cho39.png',
      'bbbc/BBBC030/cho40.png',
      'bbbc/BBBC030/cho41.png',
      'bbbc/BBBC030/cho42.png',
      'bbbc/BBBC030/cho43.png',
      'bbbc/BBBC030/cho44.png',
      'bbbc/BBBC030/cho45.png',
      'bbbc/BBBC030/cho46.png',
      'bbbc/BBBC030/cho47.png',
      'bbbc/BBBC030/cho48.png',
      'bbbc/BBBC030/cho49.png',
      'bbbc/BBBC030/cho50.png',
      'bbbc/BBBC030/cho51.png',
      'bbbc/BBBC030/cho52.png',
      'bbbc/BBBC030/cho53.png',
      'bbbc/BBBC030/cho54.png',
      'bbbc/BBBC030/cho55.png',
      'bbbc/BBBC030/cho56.png',
      'bbbc/BBBC030/cho57.png',
      'bbbc/BBBC030/cho58.png',
      'bbbc/BBBC030/cho59.png',
      'bbbc/BBBC030/cho60.png',
    ],
  },
]

export default imageSets

const spriteDirectoryToPlateObservation = Object.fromEntries(
  imageSets
    .flatMap(is => is.plateObservations)
    .filter(obs => obs != null)
    .map(obs => [obs.spriteDirectory, obs]),
)

/** Create an array of well positions for a full plate. */
function wellSequence(plateDims: PlateDims): string[] {
  return new Array(plateDims.numRows * plateDims.numCols)
    .fill(null)
    .map((_, i) => convertWellIndexToWellName(i, plateDims))
}

/**
 * Create a flat array containing every Science Corp iPSC acquisition site
 * thumbnail in a 6-well plate, in well-major order.
 */
function allScienceCorpIPSCThumbs(obsName: string, size: 200 | 2000): string[] {
  return WELL_SEQUENCE_6WP.flatMap(well =>
    new Array(16)
      .fill(null)
      .map(
        (_, i) =>
          `science/2022_11_01_cell_image_batch/${obsName}/${well}/${size}/tile-${i}.jpg`,
      ),
  )
}

/** Group an array of strings into arrays of the given size. */
function group(strings: string[], groupSize: number): string[][] {
  const groups: string[][] = []
  for (let start = 0; start < strings.length; start += groupSize) {
    groups.push(strings.slice(start, start + groupSize))
  }
  return groups
}

/**
 * Group an array of strings into wells, creating a group for each well in the
 * given sequence.
 */
function groupIntoWells(
  strings: string[],
  wellSequence: string[],
  montageSize: number,
): { [well: string]: string[] } {
  const groups = group(strings, montageSize)
  return Object.fromEntries(wellSequence.map((well, i) => [well, groups[i]]))
}
