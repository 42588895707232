import cx from 'classnames'

import { filter, find, includes, map } from 'lodash/fp'
import { useEffect, useState } from 'react'
import commonDriverAPI from '~/api/desktop/commonDriver'
import workcellAPI from '~/api/desktop/workcell'
import { Instrument } from '~/common.interface'
import { SelectOption } from '~/components/Select'
import Select from '~/components/Select'
import Toaster from '~/components/Toaster'
import TinyNotification from '~/components/notifications/TinyNotification'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { isStorageInstrument } from '~/utils/instrument'
import { StorageInstrumentConfig } from '../../components/StorageViz/StorageInstrumentConfig.interface'
import { InstrumentDescriptorForItemType } from '../LoadUnloadItems/InstrumentDescriptorForItemType.interface'
import { getAllowedInstruments } from '../LoadUnloadItems/getAllowedInstruments'
import { getAllowedInstrumentsForItemType } from '../LoadUnloadItems/getAllowedInstrumentsforItemType'
import { BulkLoadCulturePlatesConfig } from './BulkLoadCulturePlatesAction'
import BulkLoadCulturePlatesUnknownError from './BulkLoadCulturePlatesUnknownError'
import { UploadPlateDataStepData } from './BulkLoadCulturePlatesWizardData.interface'
import cs from './bulk_load_culture_plates_instrument_select.scss'
import { getAllowedShelvesForInstrument } from './getAllowedShelvesForInstrument'

export interface BulkLoadCulturePlatesInstrumentSelectProps {
  className?: string
  config: BulkLoadCulturePlatesConfig
  stepData: UploadPlateDataStepData
  setStepData: (stepData: Partial<UploadPlateDataStepData>) => void
}

const getShelfOptions = (shelves: number[]) => {
  return shelves.map(shelf => ({
    key: String(shelf),
    label: String(shelf),
  }))
}

const BulkLoadCulturePlatesInstrumentSelect = ({
  className,
  config,
  stepData,
  setStepData,
}: BulkLoadCulturePlatesInstrumentSelectProps) => {
  const isMounted = useIsMounted()
  const [allowedCulturePlateInstruments, setAllowedCulturePlateInstruments] = useState<
    InstrumentDescriptorForItemType[] | null
  >(null)

  const [allowedCulturePlateShelves, setAllowedCulturePlateShelves] = useState<
    number[]
  >([])
  const [loadingCulturePlateShelves, setLoadingCulturePlateShelves] =
    useState<boolean>(true)
  const { selectedInstrument, selectedShelf } = stepData
  const setSelectedInstrument = (
    instrument: InstrumentDescriptorForItemType | null,
  ) => {
    if (instrument != selectedInstrument) {
      setStepData({ selectedInstrument: instrument, selectedShelf: null })
    }
  }
  const setSelectedShelf = (shelf: number | null) => {
    setStepData({ selectedShelf: shelf })
  }

  const fetchAllowedCulturePlateInstruments = async () => {
    try {
      const [instrumentsResponse, workcellAllowedInstrumentsForItemTypeResponse] =
        await Promise.all([
          workcellAPI.getInstruments(),
          workcellAPI.getWorkcellAllowedInstrumentsForItemType(),
        ])
      if (!isMounted()) {
        return
      }

      const workcellAllowedInstrumentsForItemType =
        workcellAllowedInstrumentsForItemTypeResponse.workcell_allowed_instruments_for_item_type
      const allowedInstruments = filter(
        instrument => isStorageInstrument(instrument.instrument.instrumentType),
        getAllowedInstruments(
          instrumentsResponse,
          getAllowedInstrumentsForItemType(
            workcellAllowedInstrumentsForItemType,
            'culture_plate',
          ),
        ),
      )

      setAllowedCulturePlateInstruments(allowedInstruments)

      // If the instrument is already selected and is valid,
      // do not change to the default.
      if (
        selectedInstrument !== null &&
        includes(
          selectedInstrument.instrument.instrumentName,
          map('instrument.instrumentName', allowedInstruments),
        )
      ) {
        return
      }

      if (config.defaultInstrument) {
        setSelectedInstrument(
          find(
            ['instrument.instrumentName', config.defaultInstrument],
            allowedInstruments,
          ) || null,
        )
      } else {
        setSelectedInstrument(allowedInstruments[0] || null)
      }
    } catch (e) {
      if (!isMounted()) {
        return
      }
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setStepData({ unknownFetchError: true })
    }
  }

  useEffect(() => {
    fetchAllowedCulturePlateInstruments()
  }, [])

  const fetchAllowedCulturePlateShelves = async () => {
    try {
      if (!selectedInstrument) return
      setLoadingCulturePlateShelves(true)
      const instrumentConfig = await commonDriverAPI.getConfigV2(
        selectedInstrument.instrument.instrumentName,
      )
      if (!isMounted()) {
        return
      }

      const allowedShelves = getAllowedShelvesForInstrument(
        instrumentConfig.config as unknown as StorageInstrumentConfig,
        selectedInstrument.allowed_partitions || [],
      )
      setAllowedCulturePlateShelves(allowedShelves)

      // If the shelf is already selected and is valid,
      // do not change to the default.
      if (selectedShelf !== null && allowedShelves.includes(selectedShelf)) {
        setLoadingCulturePlateShelves(false)
        return
      }

      if (config.defaultShelf && allowedShelves.includes(config.defaultShelf)) {
        setSelectedShelf(config.defaultShelf)
      } else {
        setSelectedShelf(allowedShelves[0] || null)
      }
      setLoadingCulturePlateShelves(false)
    } catch (e) {
      if (!isMounted()) {
        return
      }
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setStepData({ unknownFetchError: true })
    }
  }

  useEffect(() => {
    fetchAllowedCulturePlateShelves()
  }, [selectedInstrument])

  if (stepData.unknownFetchError) {
    return <BulkLoadCulturePlatesUnknownError />
  }

  return (
    <div className={cx(className, cs.bulkLoadCulturePlatesInstrumentSelect)}>
      <div className={cs.controls}>
        <Select<Instrument>
          label='Instrument'
          items={map('instrument', allowedCulturePlateInstruments)}
          itemKey='instrumentName'
          itemLabelKey='instrumentName'
          activeItem={selectedInstrument?.instrument || null}
          onChange={instrument =>
            setSelectedInstrument(
              find(
                ['instrument.instrumentName', instrument.instrumentName],
                allowedCulturePlateInstruments,
              ) || null,
            )
          }
          triggerClassName={cs.instrumentSelectTrigger}
          popoverClassName={cs.instrumentSelectPopover}
          className={cx(cs.instrumentSelect, className)}
        />
        <Select<SelectOption>
          label='Shelf'
          items={getShelfOptions(allowedCulturePlateShelves)}
          itemKey='key'
          itemLabelKey='label'
          activeItem={
            find(
              ['key', String(selectedShelf)],
              getShelfOptions(allowedCulturePlateShelves),
            ) || null
          }
          onChange={shelf => setSelectedShelf(parseInt(shelf.key))}
          triggerClassName={cs.shelfSelectTrigger}
          popoverClassName={cs.shelfSelectPopover}
          className={cx(cs.shelfSelect, className)}
          disabled={
            loadingCulturePlateShelves ||
            !allowedCulturePlateInstruments ||
            allowedCulturePlateInstruments.length === 0
          }
        />
      </div>
      {!loadingCulturePlateShelves &&
        (!allowedCulturePlateInstruments ||
          allowedCulturePlateInstruments.length === 0) && (
          <TinyNotification
            className={cs.noValidShelves}
            type='bareError'
            message='No valid shelves on instrument. Please check the instrument configuration.'
          />
        )}
    </div>
  )
}

export default BulkLoadCulturePlatesInstrumentSelect
