import { components } from '../desktop/generated-schema'
import { client, unwrap } from '../desktopAPIv2'

export type RecordManualPassageDataSchemasResponse =
  components['schemas']['RecordManualPassageDataSchemasResponse']

export type RecordManualPassageParseDataRequest =
  components['schemas']['RecordManualPassageParseDataRequest']
export type RecordManualPassageParseDataResponse =
  | RecordManualPassageParseDataResponseWorklists
  | RecordManualPassageParseDataResponseErrors

export type RecordManualPassageParsedWorklist =
  components['schemas']['RecordManualPassageParsedWorklist']
export type RecordManualPassageParsedWorklistWarnings =
  components['schemas']['RecordManualPassageParsedWorklist']['warnings']
export type RecordManualPassageParsedWorklistWarning =
  components['schemas']['ParseCultureDataParentPlateAlreadyExistsWarning']
export type RecordManualPassageCulturePlateResponse =
  components['schemas']['RecordManualPassageValidatedCulturePlates']

export type RecordManualPassageParseDataResponseWorklists =
  components['schemas']['RecordManualPassageParseDataResponseWorklists']
export type RecordManualPassageParseDataResponseErrors =
  components['schemas']['RecordManualPassageParseDataResponseErrors']

export type RecordManualPassageSubmitDataRequest =
  components['schemas']['RecordManualPassageSubmitDataRequest']
export type RecordManualPassageSubmitDataResponse =
  components['schemas']['RecordManualPassageSubmitDataResponse']
export default {
  getParseDataSchemas: (): Promise<RecordManualPassageDataSchemasResponse> =>
    unwrap(
      client().GET('/api/v2/operator-actions/record-manual-passage/parse-data-schemas'),
    ),
  parseData: (
    request: RecordManualPassageParseDataRequest,
  ): Promise<RecordManualPassageParseDataResponse> =>
    unwrap(
      client().POST('/api/v2/operator-actions/record-manual-passage/parse-data', {
        body: request,
      }),
    ),
  submitData: (
    request: RecordManualPassageSubmitDataRequest,
  ): Promise<RecordManualPassageSubmitDataResponse> =>
    unwrap(
      client().POST('/api/v2/operator-actions/record-manual-passage/submit-data', {
        body: request,
      }),
    ),
}
