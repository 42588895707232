import { useState } from 'react'

import cs from './hitpicking_workflow.scss'

import { identity, keys, pickBy, size } from 'lodash/fp'
import { useHistory } from 'react-router'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import { useDemoMutation } from '~/demoControls/DemoContext'
import { displayCount } from '~/utils/string'
import { CleBackLink, ClePageFrame, ClePageHeader } from '../CleDemoUI/ClePageFrame'
import DoublingRateHistogram from '../charts/DoublingRateHistogram'
import KoScoreHistogram from '../charts/KoScoreHistogram'
import {
  plateAndWellValues,
  postHitpickingData,
  selectedHitPicksWells,
} from '../data/generateClePipelinePlates'
import HitpickingDataTable from './HitpickingDataTable'
import HitpickingMicroplate from './HitpickingMicroplate'

export interface HitpickingWorkflowProps {
  cellLineName: string
  useLocalAssets: boolean
}
const HitpickingWorkflow = ({
  cellLineName,
  useLocalAssets,
}: HitpickingWorkflowProps) => {
  const history = useHistory()
  const initialSelectedRowIds = selectedHitPicksWells.reduce(
    (acc, [plateAndWell]) => {
      acc[plateAndWell] = true
      return acc
    },
    {} as Record<string, boolean>,
  )
  const [selectedRowIds, setSelectedRowIds] = useState(initialSelectedRowIds)
  const updateDemoPlatesForHitpicking = useDemoMutation('cle', () => postHitpickingData)

  const handleClick = () => {
    const selectedRowIdsArr = keys(pickBy(identity, selectedRowIds))

    updateDemoPlatesForHitpicking({})
    Toaster.show({
      message: `${displayCount(
        'hit',
        size(selectedRowIdsArr),
      )} submitted for ${cellLineName}`,
      intent: 'success',
    })
    history.push('/cle-2025/pipeline')
  }

  const selectedRowIdsArr = keys(pickBy(identity, selectedRowIds))

  return (
    <ClePageFrame>
      <ClePageHeader
        title='Pick the Best Hits'
        subtitle={`Cell line: ${cellLineName}`}
        backLink={<CleBackLink url='/cle-2025/pipeline/' text='Back to Workflow' />}
      />
      <div className={cs.charts}>
        <div>
          <div className={cs.label}>KO Score</div>
          <KoScoreHistogram
            koScoreValues={Object.values(plateAndWellValues).map(
              value => value.koScore,
            )}
          />
        </div>
        <div>
          <div className={cs.label}>Doubling Rate (Hrs)</div>
          <DoublingRateHistogram
            doublingRateValues={Object.values(plateAndWellValues).map(
              value => value.doublingRate,
            )}
          />
        </div>
      </div>
      <div className={cs.plates}>
        <HitpickingMicroplate
          className={cs.plate}
          plateNumber={1}
          plateLabel={cellLineName + '_2A'}
          plateAndWellToValues={plateAndWellValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          cellLineName={cellLineName}
          useLocalAssets={useLocalAssets}
        />
        <HitpickingMicroplate
          className={cs.plate}
          plateNumber={2}
          plateLabel={cellLineName + '_2B'}
          plateAndWellToValues={plateAndWellValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          cellLineName={cellLineName}
          useLocalAssets={useLocalAssets}
        />
        <HitpickingMicroplate
          className={cs.plate}
          plateNumber={3}
          plateLabel={cellLineName + '_2C'}
          plateAndWellToValues={plateAndWellValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          cellLineName={cellLineName}
          useLocalAssets={useLocalAssets}
        />
      </div>
      <div className={cs.bottom}>
        <div className={cs.selection}>
          <div className={cs.selectionText}>
            {displayCount('hit', size(selectedRowIdsArr))} selected
          </div>
          <div className={cs.fill} />
          <Button
            type='primary'
            label='Submit Hits'
            className={cs.button}
            onClick={handleClick}
          />
        </div>
        <HitpickingDataTable
          className={cs.dataTable}
          plateAndWellToValues={plateAndWellValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
        />
      </div>
    </ClePageFrame>
  )
}

export default HitpickingWorkflow
