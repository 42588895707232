import { useState } from 'react'

import cx from 'classnames'
import { find, uniq } from 'lodash/fp'
import { WellCultureStatusGraphQl } from '~/__generated__/graphql'
import Input from '~/components/Input'
import Link from '~/components/Link'
import PopupMenu from '~/components/PopupMenu'
import Select, { SelectOption } from '~/components/Select'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import VerticalMenuIcon from '~/components/icons/VerticalMenuIcon'
import { useDemoMutation, useDemoQuery } from '~/demoControls/DemoContext'
import { displayCount } from '~/utils/string'
import { ClePhase, ClePlate } from '../clePlates/ClePlate.interface'
import {
  finalDemoData,
  initialDemoData,
  preHitpickingData,
} from '../data/generateClePipelinePlates'
import ClePipelineViz from './ClePIpelineViz'
import ClePipelineGrid from './ClePipelineGrid'
import cs from './cle_pipeline_view.scss'

const getNumPlates = (plates: ClePlate[]) => {
  return plates
    .filter(plate => plate.status === WellCultureStatusGraphQl.Active)
    .filter(plate => !plate.id.endsWith('_2D')).length
}

const getNumPlatesRequiringReview = (plates: ClePlate[]) => {
  const platesWithAlerts = plates.filter(
    plate => plate.hasAlert && plate.status === WellCultureStatusGraphQl.Active,
  )
  return platesWithAlerts.length
}

const getOwnerSelectOptions = (plates: ClePlate[]) => {
  const owners = uniq(plates.map(plate => plate.owner))
  return owners.map(owner => ({
    key: owner,
    label: owner,
  }))
}

const itemMatchesQuery = (item, queryLowerCase) =>
  item && item.label.toLowerCase().includes(queryLowerCase)

const ClePipelineView = ({
  useLocalAssets,
}: {
  useLocalAssets: boolean
}) => {
  // Just calculate the plates once per re-fresh.
  const clePipelinePlates = useDemoQuery('cle', 'ClePipeline')?.() ?? []

  const [selectedOwner, setSelectedOwner] = useState<string | null>('Dr. Jimmy Sastra')
  const [selectedPlateLabel, setSelectedPlateLabel] = useState<string | null>(null)
  const [selectedPhase, setSelectedPhase] = useState<ClePhase | null>(null)

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const resetDemoMutation = useDemoMutation('cle', () => initialDemoData)
  const fastForwardToHitpicking = useDemoMutation('cle', () => preHitpickingData)
  const fastForwardToFinal = useDemoMutation('cle', () => finalDemoData)
  const pipelineActionsOptions = [
    {
      label: 'Reset Demo',
      action: resetDemoMutation,
    },
    {
      label: 'Fast-Forward to Hitpicking...',
      action: fastForwardToHitpicking,
    },
    {
      label: 'Fast-Forward to Final...',
      action: fastForwardToFinal,
    },
  ]

  const handleSelectedOwnerChange = (value: SelectOption) => {
    setSelectedOwner(value.label)
  }

  const handleSelectedPlateLabelChange = (value: string) => {
    setSelectedPlateLabel(value)
  }

  const getFilteredPlates = () => {
    return clePipelinePlates
      .filter(plate => selectedOwner === null || plate.owner === selectedOwner)
      .filter(
        plate =>
          selectedPlateLabel === null ||
          plate.id.toLowerCase().includes(selectedPlateLabel.toLowerCase()),
      )
      .filter(plate => selectedPhase === null || plate.phase === selectedPhase)
      .filter(plate => plate.status === WellCultureStatusGraphQl.Active)
  }

  const renderControls = () => {
    const ownerSelectOptions = getOwnerSelectOptions(clePipelinePlates)
    return (
      <div className={cs.controls}>
        <Select<SelectOption>
          label='Owner'
          items={ownerSelectOptions}
          itemKey='key'
          itemLabelKey='label'
          filterable
          itemMatchesQuery={itemMatchesQuery}
          activeItem={find(['label', selectedOwner], ownerSelectOptions) || null}
          onChange={handleSelectedOwnerChange}
          className={cs.select}
          triggerClassName={cs.trigger}
          popoverClassName={cs.popover}
          allowNoneOption
          noneOptionText='All'
        />
        <Input
          label='Plate Barcode'
          placeholder='Search...'
          value={selectedPlateLabel ?? ''}
          onChange={handleSelectedPlateLabelChange}
          className={cs.select}
          inputClassName={cs.trigger}
        />
      </div>
    )
  }

  const filteredPlates = getFilteredPlates()
  const numPlatesRequiringReview = getNumPlatesRequiringReview(clePipelinePlates)

  return (
    <div className={cx(cs.clePipelineView)}>
      <div className={cs.header}>
        <div className={cs.left}>
          <div className={cs.title}>
            iPSC Engineering Workflow
            <PopupMenu
              options={pipelineActionsOptions}
              trigger={
                <a
                  className={cs.pipelineActions}
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                >
                  <VerticalMenuIcon className={cs.menuIcon} />
                </a>
              }
            />
          </div>
          <div className={cs.subtitle}>
            {displayCount('plate', getNumPlates(clePipelinePlates))} in progress
          </div>
        </div>
        <div className={cs.fill} />
        {numPlatesRequiringReview > 0 && (
          <Link
            className={cs.alertMessage}
            to={`/cle-2025/decisions/hitpicking/ACS-1020_LDK1-KO`}
          >
            <BareWarningIcon className={cs.alertIcon} />
            {displayCount('plate', numPlatesRequiringReview)} ready for hitpicking
          </Link>
        )}
      </div>
      <ClePipelineViz
        plates={clePipelinePlates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      {renderControls()}
      <ClePipelineGrid plates={filteredPlates} useLocalAssets={useLocalAssets} />
    </div>
  )
}

export default ClePipelineView
