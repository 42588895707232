import {
  MontageDimensionsGraphQl,
  MontageGraphQl,
  MontageLayoutGraphQl,
  SubImageSizeGraphQl,
  WellCultureGraphQl,
  WellCultureStatusGraphQl,
} from '~/__generated__/graphql'

import { DemoObjectConverter, LinkingError } from '../demoData'
import { getDemoMontageThumbnails, getDemoPlateSprite } from '../imageSets'
import { DemoSourceTimepoint, plateObservationKey } from './demoTimepoint'

export type DemoSourceWell = {
  plateBarcode: string
  position: string

  parentWellPlateBarcode: string
  parentWellPosition: string

  cellLine: string
  cellLineLot: string
  passageNumber: string
}

type PartialWell = Omit<WellCultureGraphQl, 'culturePlate' | 'parentCulture'> & {
  culturePlate: { barcode: string }
  parentCulture: { plateBarcode: string; position: string }
}

export const wellConverter: DemoObjectConverter<
  DemoSourceWell,
  PartialWell,
  WellCultureGraphQl
> = {
  tsvColumns: [
    'plateBarcode',
    'position',
    'parentWellPlateBarcode',
    'parentWellPosition',
    'cellLine',
    'cellLineLot',
    'passageNumber',
  ],

  validate(wells) {
    if (
      wells.length !== new Set(wells.map(w => w.plateBarcode + '|' + w.position)).size
    ) {
      throw new Error('There are wells with duplicate positions for the same plate.')
    }
    if (wells.some(w => !w.plateBarcode)) {
      throw new Error('Some wells are missing a plate barcode.')
    }
    if (wells.some(w => !w.position)) {
      throw new Error('Some wells are missing a position.')
    }
    if (wells.some(w => w.position !== w.position.toUpperCase())) {
      throw new Error('Positions must be uppercase')
    }
    if (wells.some(w => w.passageNumber && Number.isNaN(parseInt(w.passageNumber)))) {
      throw new Error('Some wells have an invalid passage number')
    }
  },

  convert(well, others) {
    const timepoints =
      others.timepoints?.filter(
        t => t.plateBarcode === well.plateBarcode && t.wellPosition === well.position,
      ) ?? []
    const latest = timepoints.length ? timepoints[timepoints.length - 1] : null

    return {
      partialItem: {
        id: encodeURIComponent(btoa(well.plateBarcode + '|' + well.position)),
        well: well.position,
        name: well.plateBarcode + '|' + well.position,
        createdAt: '2024-11-05T17:05:00.000Z',
        // Eventually, we need to thread the actual plate into here.
        culturePlate: { barcode: well.plateBarcode },
        status: WellCultureStatusGraphQl.Active,
        parentCulture: {
          plateBarcode: well.parentWellPlateBarcode,
          position: well.parentWellPosition,
        },
        cellLine: well.cellLine,
        cellLineLot: well.cellLineLot,
        passageNumber:
          well.passageNumber != '' && well.passageNumber != null
            ? parseInt(well.passageNumber)
            : null,
        confluence: latest
          ? {
              lastUpdatedAt: latest.timestamp,
              source: {
                __typename: 'DatasetSourceGraphQL',
                datasetId: plateObservationKey(latest),
              },
              value: parseFloat(latest.confluence),
            }
          : null,
        confluenceHistory: timepoints.map(t => ({
          __typename: 'PastWellConfluenceGraphQL',
          source: {
            __typename: 'DatasetSourceGraphQL',
            datasetId: plateObservationKey(t),
          },
          timestamp: t.timestamp,
          value: parseFloat(t.confluence),
        })),
        montage: latest ? timepointToMontageGraphQL(latest, well.position) : null,
        montages: timepoints.map(t => timepointToMontageGraphQL(t, well.position)),
        montageHistory: timepoints.map(t =>
          timepointToMontageGraphQL(t, well.position),
        ),
        observationHistory: timepoints.map(t => ({
          __typename: 'PastObservationGraphQL',
          source: {
            __typename: 'DatasetSourceGraphQL',
            datasetId: plateObservationKey(t),
          },
          timestamp: t.timestamp,
          confluence: parseFloat(t.confluence),
          montage: timepointToMontageGraphQL(t, well.position),
        })),
      },
      lookupKey: demoWellKey(well),
    }
  },

  link(derived, partial, lookup) {
    const barcode = partial.culturePlate.barcode
    const plate = lookup('plates', barcode)
    if (!plate) {
      throw new LinkingError(
        `A well is defined with plate barcode "${barcode}" but there is no plate defined with that barcode.`,
      )
    }
    derived.culturePlate = plate

    derived.parentCulture = lookup('wells', demoWellKey(partial.parentCulture))
    if (
      !derived.parentCulture &&
      (partial.parentCulture.plateBarcode || partial.parentCulture.position)
    ) {
      throw new LinkingError(
        `A well has a parent culture (plate "${partial.parentCulture.plateBarcode}" well "${partial.parentCulture.position}") that does not exist.`,
      )
    }

    derived.createdAt = plate.firstCheckedInAt
    if (!plate.isCheckedIn) {
      derived.status = WellCultureStatusGraphQl.Terminated
    }

    for (const montage of [derived.montage]
      .concat(derived.montages)
      .concat(derived.montageHistory)
      .concat(derived.observationHistory.map(obs => obs.montage))) {
      if (montage) {
        montage.culture = derived
      }
    }
  },
}

export function demoWellKey({
  plateBarcode,
  position,
}: { plateBarcode: string; position: string }): string {
  return plateBarcode + '|' + position
}

function timepointToMontageGraphQL(
  t: DemoSourceTimepoint,
  wellPos: string,
): MontageGraphQl & { largeImages: MontageGraphQl['images'] } {
  const thumbs = getDemoMontageThumbnails(t.plateImageSet, wellPos, 2000)
  const { montageLayout } = getDemoPlateSprite(
    t.plateImageSet,
    SubImageSizeGraphQl.Size_200X200,
  )
  const images = thumbs.map((url, i) => ({
    __typename: 'ImageGraphQL' as const,
    id: `${plateObservationKey(t)}_${wellPos}_${i}`,
    imageUrl: url,
    key: url,
    montageId: `${plateObservationKey(t)}_${wellPos}`,
    montageIndex: i,
  }))

  return {
    __typename: 'MontageGraphQL',
    id: plateObservationKey(t),
    dimensions: MONTAGE_LAYOUT_TO_DIMENSIONS[montageLayout],
    images,
    largeImages: images, // Hack for MontageImagesDialogFragment to work.
    culture: null as unknown as WellCultureGraphQl, // Populate later, during wellConverter.link()
  }
}

const MONTAGE_LAYOUT_TO_DIMENSIONS: {
  [key in MontageLayoutGraphQl]: MontageDimensionsGraphQl
} = {
  [MontageLayoutGraphQl.Single]: {
    __typename: 'MontageDimensionsGraphQL',
    rows: 1,
    cols: 1,
  },
  [MontageLayoutGraphQl.Square_2X2]: {
    __typename: 'MontageDimensionsGraphQL',
    rows: 2,
    cols: 2,
  },
  [MontageLayoutGraphQl.Square_3X3]: {
    __typename: 'MontageDimensionsGraphQL',
    rows: 3,
    cols: 3,
  },
  [MontageLayoutGraphQl.Square_4X4]: {
    __typename: 'MontageDimensionsGraphQL',
    rows: 4,
    cols: 4,
  },
}
