import { includes } from 'lodash/fp'
import { useEffect, useState } from 'react'
import executeTaskEndpoint, { TaskExecutionResult } from '~/api/executeTaskEndpoint'
import { BulkLoadParsedPlateWithStorageLoadLocation } from '~/api/operatorActions/bulkLoadCulturePlates'
import reloadItemsAPI from '~/api/operatorActions/reloadItems'
import operatorActionAPI from '~/api/operatorActions/shared'
import { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import LoadingMessageBox from '~/components/LoadingMessageBox'
import Toaster from '~/components/Toaster'
import BigNotification from '~/components/notifications/BigNotification'
import Notification from '~/components/notifications/Notification'
import { analytics } from '~/core/analytics'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { displayCount } from '~/utils/string'
import { OperatorActionOption } from '../OperatorActionOption'
import { ReloadOperationRequest } from '../reloadItems/convertChangeToReloadOperation'
import { useEnabledAndUnusedOperatorActionNames } from '../useEnabledAndUnusedOperatorActionNames'
import BulkLoadCulturePlatesUnknownError from './BulkLoadCulturePlatesUnknownError'
import {
  PlateContents,
  ReloadItemSuccessResponse,
  SubmitStepData,
  UploadLiveWellsStepValidData,
  UploadPlateDataStepValidData,
} from './BulkLoadCulturePlatesWizardData.interface'
import {
  getBulkLoadPreviewPlates,
  getPlateLiveWells,
  getTotalLiveWells,
} from './getBulkLoadPreviewPlates'
import cs from './submit_step.scss'

interface SubmitStepProps {
  stepData: SubmitStepData
  setStepData: (stepData: Partial<SubmitStepData>) => void
  uploadPlateDataStepData: UploadPlateDataStepValidData
  uploadLiveWellsStepData: UploadLiveWellsStepValidData
}

export const assembleReloadOperations = (
  uploadPlateDataStepData: UploadPlateDataStepValidData,
  uploadLiveWellsStepData: UploadLiveWellsStepValidData,
): ReloadOperationRequest[] => {
  const plateContentsToType: Record<
    PlateContents,
    'empty_culture_plate' | 'culture_plate'
  > = {
    'pre-coated': 'empty_culture_plate',
    'live-culture': 'culture_plate',
  }

  return uploadPlateDataStepData.parseDataResponse.data.map(
    (plateWithStorageLoadLocation: BulkLoadParsedPlateWithStorageLoadLocation) => {
      return {
        instrument_name:
          uploadPlateDataStepData.selectedInstrument.instrument.instrumentName,
        command: 'load_process_item',
        command_params: {
          level_index: plateWithStorageLoadLocation.level,
          shelf_index: uploadPlateDataStepData.selectedShelf,
        },
        manually_move: true,
        type: plateContentsToType[uploadPlateDataStepData.selectedPlateContents],
        use_arm_assist: false,
        process_item_params: {
          labware_name: plateWithStorageLoadLocation.labware,
          uuid: plateWithStorageLoadLocation.plate_barcode,
          specific_wells_to_fill: getPlateLiveWells(
            plateWithStorageLoadLocation.plate_barcode,
            plateWithStorageLoadLocation.plate_format,
            uploadPlateDataStepData,
            uploadLiveWellsStepData,
          ),
        },
      }
    },
  )
}

const SubmitStep = ({
  stepData,
  setStepData,
  uploadPlateDataStepData,
  uploadLiveWellsStepData,
}: SubmitStepProps) => {
  const isMounted = useIsMounted()
  const [operatorActionConfigs, setOperatorActionConfigs] =
    useState<EnabledOperatorConfigs | null>(null)
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()
  const setExecutingTask = (executingTask: boolean) => {
    setStepData({
      submitInProgress: executingTask,
    })
  }

  const fetchOperatorActionConfigs = () => {
    operatorActionAPI.getAllEnabledConfigs().then(allEnabledConfigs => {
      if (isMounted()) {
        setOperatorActionConfigs(allEnabledConfigs.configs)
      }
    })
  }

  useEffect(() => {
    fetchOperatorActionConfigs()
  }, [])

  const performBulkLoad = async () => {
    const operations = assembleReloadOperations(
      uploadPlateDataStepData,
      uploadLiveWellsStepData,
    )

    let reloadResponse: TaskExecutionResult<ReloadItemSuccessResponse> | null = null

    try {
      reloadResponse = await executeTaskEndpoint<ReloadItemSuccessResponse>(
        () =>
          reloadItemsAPI.submit({
            operations,
          }),
        setExecutingTask,
        () => {},
      )
    } catch (e) {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      if (!isMounted()) {
        return
      }
      setStepData({
        reloadResponse: null,
      })
      return
    }
    if (!isMounted()) {
      return
    }
    setStepData({
      reloadResponse,
    })
  }

  const renderSuccessNotification = (
    uploadPlateDataStepData: UploadPlateDataStepValidData,
  ) => {
    const message = `Successfully loaded ${displayCount('plate', uploadPlateDataStepData.parseDataResponse.data.length)} onto ${uploadPlateDataStepData.selectedInstrument.instrument.instrumentName}`

    return (
      <BigNotification
        message={message}
        type='success'
        className={cs.successNotification}
      />
    )
  }

  useEffect(() => {
    performBulkLoad()
  }, [])

  useEffect(() => {
    if (stepData.reloadResponse?.success) {
      const previewPlates = getBulkLoadPreviewPlates(
        uploadPlateDataStepData,
        uploadLiveWellsStepData,
      )
      const numPlates = previewPlates.length
      const numLiveWells = getTotalLiveWells(previewPlates)
      analytics.track('Completed Bulk Load Culture Plates', {
        numPlates,
        numLiveWells,
      })
    }
  }, [stepData.reloadResponse])

  if (stepData.submitInProgress) {
    return (
      <LoadingMessageBox
        label='Loading Culture Plates...'
        className={cs.loadingMessageBox}
      />
    )
  }
  // This means there was an HTTP error during performBulkLoad.
  if (!stepData.reloadResponse) {
    return <BulkLoadCulturePlatesUnknownError />
  }

  if (!stepData.reloadResponse.success) {
    return (
      <Notification
        className={cs.submitError}
        variant='mini'
        type='error'
        label={stepData.reloadResponse.error}
      />
    )
  }

  const renderUploadLinks = () => {
    if (!operatorActionConfigs) return null

    if (
      !includes('link_cell_line_lots_to_cultures', enabledOperatorActionNames) &&
      !includes('record_manual_passage', enabledOperatorActionNames)
    )
      return

    return (
      <div className={cs.contents}>
        <div className={cs.text}>Ready to upload metadata for these cultures?</div>
        <div className={cs.operatorActionLinks}>
          {includes('link_cell_line_lots_to_cultures', enabledOperatorActionNames) && (
            <OperatorActionOption
              actionName='link_cell_line_lots_to_cultures'
              operatorActionConfigs={operatorActionConfigs}
              variant='condensed'
            />
          )}
          {includes('record_manual_passage', enabledOperatorActionNames) && (
            <OperatorActionOption
              actionName='record_manual_passage'
              operatorActionConfigs={operatorActionConfigs}
              variant='condensed'
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={cs.submitStep}>
      {renderSuccessNotification(uploadPlateDataStepData)}
      {renderUploadLinks()}
    </div>
  )
}

export default SubmitStep
