import { useFragment } from '@apollo/client'
import { Link } from 'react-router-dom'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'

import RightArrowIcon from '~/components/icons/RightArrowIcon'
import cs from './breadcrumbs_cle_2025.scss'

export default function BreadcrumbsCle2025({
  plate,
  wellPos,
  routePrefix,
}: { plate?: PlateProps; wellPos?: string; routePrefix: string }) {
  return (
    <nav className={cs.breadcrumbs}>
      {routePrefix === '' ? (
        <Link key='root' to='/monitor/live-cultures'>
          Plates
        </Link>
      ) : null}
      {routePrefix === '/cle-2025' ? (
        <Link key='root' to='/cle-2025/pipeline'>
          iPSC Engineering Workflow
        </Link>
      ) : null}

      {plate?.id != null ? (
        <>
          <PlateCrumb
            plate={plate}
            active={wellPos == null}
            routePrefix={routePrefix}
          />

          {wellPos != null ? (
            <WellCrumb
              plateID={plate.id}
              wellPos={wellPos}
              routePrefix={routePrefix}
              active
            />
          ) : null}
        </>
      ) : null}
    </nav>
  )
}

const plateCrumbFragment = gql(`
  fragment PlateCrumbFragment on CulturePlateGraphQL {
    id # Not used by PlateCrumb, but required for queries to seed the cache.
    barcode
  }
`)

interface PlateProps {
  /** Can be from URL if not yet fetched. */
  id: string
  /** Can be null if not yet fetched. */
  data: FragmentType<typeof plateCrumbFragment> | null
}

/**
 * Render a link to the plate, falling back to the Apollo cache if the barcode
 * hasn't been fetched yet.
 */
function PlateCrumb({
  plate,
  active,
  routePrefix,
}: { plate: PlateProps; active: boolean; routePrefix: string }) {
  const { id, data: queried } = plate
  const { data: cached } = useFragment({
    fragment: plateCrumbFragment,
    from: { __typename: 'CulturePlateGraphQL', id },
  })

  const barcode = unmaskFragment(plateCrumbFragment, queried)?.barcode ?? cached.barcode
  if (!barcode) {
    return null
  }

  return (
    <>
      <RightArrowIcon key='platesep' className={cs.separator} />
      <Link
        key='plate'
        to={`${routePrefix}/plate/${plate.id}`}
        className={active ? cs.active : null}
      >
        {barcode}
      </Link>
    </>
  )
}

function WellCrumb({
  plateID,
  wellPos,
  active,
  routePrefix,
}: { plateID: string; wellPos: string; active: boolean; routePrefix: string }) {
  return (
    <>
      <RightArrowIcon key='wellsep' className={cs.separator} />
      <Link
        key='well'
        to={`${routePrefix}/plate/${plateID}/well/${wellPos}`}
        className={active ? cs.active : null}
      >
        {wellPos}
      </Link>
    </>
  )
}
