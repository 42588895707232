import { useAuth0 } from '@auth0/auth0-react'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { useFeatureFlag } from '~/core/featureFlags'

import { DemoErrorsByKind, DemoSourceRecordsByKind, prepareDemoData } from './demoData'
import { DemoQueries, getDemoQueries } from './demoQueries'
import { STATIC_DEMO_SOURCE_STATE_BY_ORG_ID } from './staticDemos/staticDemos'

/** Namespaces for separating demo data. */
export type DemoProduct = 'monitor' | 'cle'
export const DEMO_PRODUCTS: DemoProduct[] = ['monitor', 'cle']

/**
 * The full set of persisted source state for the demo, comprising demo data and
 * other UI state. Keep it small and backwards compatible.
 */
interface DemoSourceState {
  /** State of the toggle on the demo controls page. */
  showDemoData?: boolean

  monitor?: DemoSourceRecordsByKind
  cle?: DemoSourceRecordsByKind
}

/**
 * A set of derived data for a particular demo product.
 */
interface DemoDerivedData {
  sourceData: DemoSourceRecordsByKind
  queries?: DemoQueries
  errors?: DemoErrorsByKind
}

/**
 * The full set of derived state. This is regenerated every time the source
 * state changes.
 */
export interface DemoDerivedState {
  /**
   * Whether to show demo data at all. This may not be equal to the current
   * state of the toggle.
   */
  showDemoData: boolean

  /**
   * Whether to force showing a pre-defined demo instead of a customized demo.
   * This also disables the demo controls page.
   */
  isStatic: boolean

  /** Demo data for plate and well views. */
  monitor: DemoDerivedData

  /** Demo data for CLE. @deprecated */
  cle: DemoDerivedData
}

const NO_DERIVED_DATA = { sourceData: {} }

const DemoStateContext = createContext<DemoDerivedState>({
  showDemoData: false,
  isStatic: false,
  monitor: NO_DERIVED_DATA,
  cle: NO_DERIVED_DATA,
})
const DemoDispatchContext = createContext<React.Dispatch<DemoSourceState>>(() => {
  throw new Error('Cannot modify demo controls state without a <DemoDataProvider>')
})

function prepareDerivedData(sourceData?: DemoSourceRecordsByKind): DemoDerivedData {
  const { data, errors } = prepareDemoData(sourceData ?? {})
  const queries = data ? getDemoQueries(data) : undefined
  return { sourceData: sourceData ?? {}, queries, errors }
}

/**
 * Context provider that loads and saves source state, and computes derived
 * state.
 */
export function DemoDataProvider({ children }: React.PropsWithChildren<unknown>) {
  const featureEnabled = useFeatureFlag('demoControls')
  const [localSourceState, setLocalSourceState] = useState<DemoSourceState>(
    loadLocalSourceState(),
  )

  const { user } = useAuth0()
  const staticSourceState = STATIC_DEMO_SOURCE_STATE_BY_ORG_ID[user?.org_id]

  const sourceState = staticSourceState ?? localSourceState
  const derivedState: DemoDerivedState = useMemo(
    () => ({
      showDemoData: Boolean(featureEnabled) && Boolean(sourceState.showDemoData),
      isStatic: staticSourceState != null,
      monitor: prepareDerivedData(sourceState.monitor),
      cle: prepareDerivedData(sourceState.cle),
    }),
    [sourceState, featureEnabled],
  )

  const updateSourceState = useCallback(
    (newState: DemoSourceState) => {
      persistLocalSourceState(newState)
      setLocalSourceState(newState)
    },
    [setLocalSourceState],
  )

  return (
    <DemoStateContext.Provider value={derivedState}>
      <DemoDispatchContext.Provider value={updateSourceState}>
        {children}
      </DemoDispatchContext.Provider>
    </DemoStateContext.Provider>
  )
}

export function useDemoToggleState(): {
  isActive: boolean
  isStatic: boolean
  onChange: (newValue: boolean) => void
} {
  const state = useContext(DemoStateContext)
  const dispatch = useContext(DemoDispatchContext)
  return {
    isActive: state.showDemoData,
    isStatic: state.isStatic,
    onChange: (newValue: boolean) => {
      dispatch({
        showDemoData: newValue,
        monitor: state.monitor.sourceData,
        cle: state.cle.sourceData,
      })
    },
  }
}

export function useDemoDataState(
  product: DemoProduct,
): [DemoDerivedData, (newSourceData: DemoSourceRecordsByKind) => void] {
  const state = useContext(DemoStateContext)
  const dispatch = useContext(DemoDispatchContext)
  return [
    state.showDemoData ? state[product] : NO_DERIVED_DATA,
    (newSourceData: DemoSourceRecordsByKind) => {
      const cleanedState = { ...state, isStatic: undefined }
      dispatch({
        ...cleanedState,
        monitor: state.monitor.sourceData,
        cle: state.cle.sourceData,
        [product]: newSourceData,
      })
    },
  ]
}

/**
 * Returns one of the query functions defined in demoQueries.ts. Returns null if
 * the demo is disabled, or if demo data could not be generated.
 */
export function useDemoQuery<QueryName extends keyof DemoQueries>(
  product: DemoProduct,
  name: QueryName,
): DemoQueries[QueryName] | null {
  const [state] = useDemoDataState(product)
  return state.queries?.[name] ?? null
}

/**
 * Creates a callback for modifying demo state.
 *
 * Takes a reducer function, which itself takes the current demo source data and
 * an action to apply to it, and returns a new set of demo source data.
 *
 * Avoid calling the returned callback in hot paths. The demo state will be
 * re-generated, re-serialized, and re-persisted; and all components that use
 * demo queries will re-render.
 */
export function useDemoMutation<T>(
  product: DemoProduct,
  reducer: (demoData: DemoSourceRecordsByKind, payload: T) => DemoSourceRecordsByKind,
): (payload: T) => void {
  const [data, setData] = useDemoDataState(product)
  return useCallback(
    (payload: T) => {
      setData(reducer(data.sourceData ?? {}, payload))
    },
    [reducer, data, setData],
  )
}

const LOCAL_STORAGE_KEY = 'monomer_demo_state'

function loadLocalSourceState(): DemoSourceState {
  try {
    return JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}',
    ) as DemoSourceState
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.error('Failed to parse demo data from local storage', e)
      return {}
    }
    throw e
  }
}

function persistLocalSourceState(state: DemoSourceState) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
}
