import cx from 'classnames'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import ClePlateViz from '../ClePlate/ClePlateViz'
import { ClePlate, getClePhaseName } from '../clePlates/ClePlate.interface'
import cs from './cle_pipeline_grid_item.scss'

const ClePipelineGridItem = ({
  plate,
  onClickCellLine,
  onClickCell,
  useLocalAssets,
}: {
  plate: ClePlate
  onClickCellLine: (() => void) | undefined
  onClickCell: (() => void) | undefined
  useLocalAssets: boolean
}) => {
  return (
    <div className={cx(cs.clePipelineItem, plate.hasAlert && cs.hasAlert)}>
      <div
        className={cx(cs.cellLineName, onClickCellLine != null ? cs.clickable : null)}
        onClick={onClickCellLine}
      >
        {plate.hasAlert && <BareWarningIcon className={cs.alertIcon} />}
        {plate.id}
      </div>
      <ClePlateViz
        plate={plate}
        onClickCell={onClickCell}
        useLocalAssets={useLocalAssets}
      />
      <div className={cs.plateType}>{getClePhaseName(plate)}</div>
    </div>
  )
}

export const ClePipelineItemSpacer = () => {
  return <div className={cs.clePipelineItem} />
}

export default ClePipelineGridItem
