import {} from 'react'
import { RecordManualPassageParseDataResponseWorklists } from '~/api/operatorActions/recordManualPassage'
import BigNotification from '~/components/notifications/BigNotification'
import { displayCount } from '~/utils/string'
import RecordManualPassageWorklistPreview from './RecordManualPassageWorklistPreview'
import {
  getNumDistinctCultures,
  getNumDistinctPlates,
} from './parseDataResponseExtractors'
import cs from './success_step.scss'

type SuccessStepProps = {
  parseDataResponse: RecordManualPassageParseDataResponseWorklists
}

const SuccessStep = ({ parseDataResponse }: SuccessStepProps) => {
  const renderSuccessNotification = () => {
    const message =
      `Successfully recorded passages for ` +
      `${displayCount('culture', getNumDistinctCultures(parseDataResponse))} ` +
      `(${displayCount('plate', getNumDistinctPlates(parseDataResponse))})`

    return (
      <BigNotification
        message={message}
        type='success'
        className={cs.successNotification}
      />
    )
  }

  return (
    <div className={cs.successStep}>
      {renderSuccessNotification()}
      <div className={cs.contents}>
        <div className={cs.dataTableColumn}>
          <RecordManualPassageWorklistPreview
            worklists={parseDataResponse.data}
            plates={parseDataResponse.culture_plates}
            renderWarnings={false}
            className={cs.dataTable}
          />
        </div>
      </div>
    </div>
  )
}

export default SuccessStep
