import stemCellCo from './stemCellCo.json'

/**
 * To force an organization to show hard-coded demo data and disable the demo
 * controls page, declare it here.
 *
 * The org MUST also have the appropriate `featureFlags` and SHOULD have the
 * appropriate `monomer_enabled_pages` fields configured in the org metadata.
 */
export const STATIC_DEMO_SOURCE_STATE_BY_ORG_ID = {
  org_YKmfVdfEQvlzvNa3: stemCellCo, // Prod
  org_nTtOcKt2YenXT862: stemCellCo, // Dev
}
