import cx from 'classnames'

import { WellCultureStatusGraphQl } from '~/__generated__/graphql'
import TinyMicroplate from '~/components/TinyMicroplate'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { useDemoQuery } from '~/demoControls/DemoContext'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import { CleCulturePopover } from '../clePlates/CleCulturePopover'
import { ClePhase, ClePlate } from '../clePlates/ClePlate.interface'
import {
  DOUBLING_RATE_BEST_BOUNDS,
  DOUBLING_RATE_GOOD_BOUNDS,
  HitpickingValues,
  KO_SCORE_HETEROZYGOUS_BOUNDS,
  KO_SCORE_HOMOZYGOUS_BOUNDS,
  getPlateAndWellId,
} from '../data/hitpickingData'
import cs from './hitpicking_microplate.scss'

export interface HitpickingMicroplateProps {
  className?: string
  plateNumber: number
  plateLabel: string
  plateAndWellToValues: Record<string, HitpickingValues>
  selectedRowIds: Record<string, boolean>
  setSelectedRowIds: (rowIds: Record<string, boolean>) => void
  cellLineName: string
  useLocalAssets: boolean
}

const getCellColor = (value: HitpickingValues) => {
  if (
    value.koScore >= KO_SCORE_HETEROZYGOUS_BOUNDS[0] &&
    value.koScore <= KO_SCORE_HETEROZYGOUS_BOUNDS[1]
  ) {
    if (
      value.doublingRate >= DOUBLING_RATE_BEST_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_BEST_BOUNDS[1]
    ) {
      return '#3994C1'
    }
    if (
      value.doublingRate >= DOUBLING_RATE_GOOD_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_GOOD_BOUNDS[1]
    ) {
      return '#A7D8F0'
    }
  }

  if (
    value.koScore >= KO_SCORE_HOMOZYGOUS_BOUNDS[0] &&
    value.koScore <= KO_SCORE_HOMOZYGOUS_BOUNDS[1]
  ) {
    if (
      value.doublingRate >= DOUBLING_RATE_BEST_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_BEST_BOUNDS[1]
    ) {
      return '#8662C7'
    }
    if (
      value.doublingRate >= DOUBLING_RATE_GOOD_BOUNDS[0] &&
      value.doublingRate <= DOUBLING_RATE_GOOD_BOUNDS[1]
    ) {
      return '#CFBCF2'
    }
  }
  return '#EAEAEA'
}

const getPlaceholderHitpickingPlateForPopover = (
  plateLabel: string,
  plateNumber: number,
  parentCellLine: string,
  desiredEdit: string,
): ClePlate => ({
  id: plateLabel,
  plateFormat: 'wells_24' as supportedPlateFormats,
  hasAlert: false,
  cellLine: {
    parentCellLine,
    desiredEdit,
    cellLineIndex: 0,
    name: `${parentCellLine}-${desiredEdit}`,
  },
  phase: 'hitpicking' as ClePhase,
  plateNumber,
  wells: {},
  owner: 'Dr. Jimmy Sastra',
  status: WellCultureStatusGraphQl.Active,
})

const HitpickingMicroplate = ({
  className,
  plateNumber,
  plateLabel,
  plateAndWellToValues,
  selectedRowIds,
  setSelectedRowIds,
  cellLineName,
  useLocalAssets,
}: HitpickingMicroplateProps) => {
  const handleClickCell = (row: number, col: number) => {
    const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
    setSelectedRowIds({
      ...selectedRowIds,
      [plateAndWellId]: !selectedRowIds[plateAndWellId],
    })
  }
  const plateData = useDemoQuery('cle', 'ClePipeline')?.()
  const plate = plateData?.find(plate => plate.id === plateLabel)

  const tokens = cellLineName.split('-')
  const parentCellLine = `${tokens[0]}-${tokens[1]}`
  const desiredEdit = `${tokens[2]}-${tokens[3]}`

  return (
    <div className={cx(cs.plate, className)}>
      <TinyMicroplate
        size='extraLarge'
        plateFormat='wells_24'
        highlights={[
          {
            colorRgbFn: (row, col) => {
              const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
              if (selectedRowIds[plateAndWellId]) {
                return '#2cb1bc'
              }
              const value = plateAndWellToValues[plateAndWellId]
              return getCellColor(value)
            },
          },
        ]}
        showCheckIcon={(row, col) => {
          const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
          return selectedRowIds[plateAndWellId]
        }}
        onClickCell={handleClickCell}
        getPopoverContent={(row, col) => {
          const wellName = convertWellCoordsToWellName(row, col)
          const plateAndWellId = getPlateAndWellId(plateNumber, row, col)
          const confluency =
            plate?.wells[wellName]?.confluency ??
            plateAndWellToValues[plateAndWellId].confluency
          return (
            <CleCulturePopover
              clePlate={plate as ClePlate}
              wellName={wellName}
              dayToSimulate={3}
              actualConfluency={confluency}
              useLocalAssets={useLocalAssets}
            />
          )
        }}
      />
      <div className={cs.plateLabel}>{plateLabel}</div>
    </div>
  )
}

export default HitpickingMicroplate
